import React from 'react';
import './Help.css'; // Votre CSS pour le tableau de bord
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Help = () => {
  return (
    <div className="help">
      <div className="help-header">
      <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
        <img src="logo_bis.svg" alt="logo_bis" />
        <h1 style={{marginLeft:10}}>FAQ</h1>
      </Box>
      </div>     

      <div style={{marginLeft: 10, marginRight:10, alignItems: 'left'}}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <h2>Est-il possible de modifier une recette après son ajout ?</h2>
            </AccordionSummary>
            <AccordionDetails className='accordion-details' >
            Bien sûr !<br/>
            Vous pouvez modifier une recette à tout moment.<br/>
            Accédez à la recette concernée dans la section "Mes recettes", puis cliquez sur “Consulter” dans le bouton à droite, <br/>
            et enfin sur le bouton "Modifier". <br/>
            Apportez les modifications souhaitées et cliquez sur "Sauvegarder" pour mettre à jour la recette.<br/>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <h2>Comment analyser les valeurs nutritionnelles de mes plats sur MyFoodPrint ?</h2>
            </AccordionSummary>
            <AccordionDetails className='accordion-details' >
            → Lorsque la recette est ajoutée sur la plateforme, cliquez sur l’onglet “Mes recettes” sur le bandeau de gauche<br/>
            → Cherchez la recette que vous souhaitez analyser. Puis cliquer sur le bouton “consulter” sur la droite de la recette. <br/>
            → Le système vous montrera alors automatiquement les valeurs nutritionnelles telles que les calories, les protéines, les lipides, et les glucides.<br/>
            Vous pouvez exporter ce rapport en cliquant sur “Exporter l’analyse”, juste en dessous du bouton “Consulter”.<br/>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <h2>Comment faire pour supprimer une recette de mon compte ?</h2>
            </AccordionSummary>
            <AccordionDetails className='accordion-details' >
            Si vous souhaitez supprimer une recette, allez dans "Mes recettes", sélectionnez la recette que vous voulez supprimer, et cliquez sur "Supprimer". Confirmez votre choix pour finaliser la suppression.<br/>

            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <h2>Comment puis-je intégrer une recette sur la plateforme ?</h2>
            </AccordionSummary>
            <AccordionDetails className='accordion-details' >
            Pour intégrer une recette à la plateforme la marche à suivre est très simple : <br/>

            → Connectez-vous à votre compte, allez dans la section "Mes recettes" <br/>

            → Cliquez sur "Ajouter une recette" puis suivez ensuite les instructions pour entrer les détails de la recette, y compris les ingrédients, les grammages etc. <br/>

            → N'oubliez pas de sauvegarder votre recette une fois toutes les informations complétées en lui donnant un nom et pensez bien à ajouter le nombre de portions produites pour ne pas fausser le résultat<br/>

            Ps : Si vous avez les fiches techniques, nous pouvons faire l’intégration pour vous, sans que vous n’ayez rien à faire ! Pour cela, il vous suffit de nous envoyer vos fiches techniques. Nous favorisons le format Excel, mais nous pouvons aussi envisager d’autres formats.<br/>

            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <h2>Comment puis-je partager une recette avec d'autres utilisateurs de la plateforme ?</h2>
            </AccordionSummary>
            <AccordionDetails className='accordion-details' >
              Cette fonctionnalitée est en cours de développement. Elle sera bientôt disponible.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <h2>Comment puis-je trouver des recettes spécifiques sur la plateforme ?</h2>
            </AccordionSummary>
            <AccordionDetails className='accordion-details' >
            Allez dans l’onglet “Mes recettes”, visible sur le menu de gauche.<br/>
            Utilisez la barre de recherche en haut de la page et tapez les mots-clés relatifs à la recette que vous cherchez. <br/>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <h2>Je ne trouve pas l’ingrédient que je souhaite, comment faire ?</h2>
            </AccordionSummary>
            <AccordionDetails className='accordion-details' >
            Nous ajoutons constamment des ingrédients à notre base de données. <br/>
            Si vous ne trouvez pas un ingrédient, commencez par chercher un synonyme (par exemple, courge et courgette) ou un produit similaire (par exemple, chou et chou blanc).<br/>  
            Si vous ne trouvez toujours pas votre ingrédient, n'hésitez pas à nous envoyer un message à mathias.bossaerts@foodprint.eco et <br/> nous vous suggérerons un ingrédient de substitution à utiliser pendant que nous travaillons à inclure celui qui manque!
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <h2>La plateforme propose-t-elle des suggestions de recettes ?</h2>
            </AccordionSummary>
            <AccordionDetails className='accordion-details' >
            Cette fonctionnalitée est en cours de développement. Elle sera bientôt disponible.

            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <h2>Quel est le poids approximatif d’un repas standard ?</h2>
            </AccordionSummary>
            <AccordionDetails className='accordion-details' >
              Le poids d’un déjeuner ou dîner standard est d’environ 400 grammes.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <h2>Quelles émissions sont comptabilisées dans les calculs d’impact ?</h2>
            </AccordionSummary>
            <AccordionDetails className='accordion-details' >
            Chaque produit alimentaire passe par plusieurs étapes pendant le processus de production, y compris l'extraction de matières premières, l'agriculture, le traitement en usine et le transport. <br/>
            Toutes ces étapes libèrent diverses quantités d'émissions, communément appelées émissions de gaz à effet de serre. <br/>
            Les gaz à effet de serre les plus courants sont le dioxyde de carbone, le méthane et l'oxyde nitreux et la quantité d'émissions libérées <br/>est mesurée en équivalents de dioxyde de carbone (équivalents CO2, CO2e) pour avoir une unité standardisée permettant de comparer l'effet des différentes émissions de gaz.
            </AccordionDetails>
          </Accordion>
      </div>

      <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
        <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: 1, marginRight: 1 }}>
          <h3>Vous avez un autre problème ou une question ?</h3>
          <p> Vous pouvez nous contacter par mail à l'adresse suivante : mathias.bossaerts@foodprint.eco</p>
        </Box>
      </Box>

    </div>
  );
};

export default Help;
