import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import test_illustration from './test_illustration.jpg';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({ altRecipe }) {
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [portions, setPortions] = React.useState(50); // État pour le nombre de portions

  const getScoreColor = (score) => {
    if (score >= 80) {
      return 'green';
    } else if (score >= 50) {
      return 'orange';
    } else {
      return 'red';
    }
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePortionsChange = (event) => {
    setPortions(event.target.value);
  };

  var scoreColor = getScoreColor(parseInt(altRecipe.ecoscore100));
  if (!altRecipe.ingredients) {
    return (<div></div>)
  }

  return (
    <div>
      <Card sx={{ maxWidth: 345, borderRadius: 5 }}>
        <CardHeader title={altRecipe.name} />
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
            <img
              src={`./ecoscore/${altRecipe.ecoscore}.svg`}
              alt={`${altRecipe.ecoscore}`}
              height="12%"
              width="12%"
            />
            <img
              src={`./nutriscore/${altRecipe.nutriscore}.svg`}
              alt={`${altRecipe.nutriscore}`}
              height="30%"
              width="30%"
            />
          </div>
          <Typography variant="body2" color="text.secondary">
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{ textAlign: 'left' }}>
            <p><strong>Score ecologique : <span style={{ color: scoreColor }}>{altRecipe.ecoscore100}/100</span></strong></p>
            <p><strong>Empreinte carbone : {altRecipe.footprint}gCO2eq/portion</strong></p>
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Ingredients :
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{ textAlign: 'left' }}>
            <ul>
              {altRecipe.ingredients.map((ingredient) => (
                <li key={ingredient}>{ingredient}</li>
              ))}
            </ul>
          </Typography>
        </CardContent>
        <CardActions disableSpacing sx={{ justifyContent: 'center' }}>
          <Button size="small" onClick={handleClickOpen}>Voir les details de la recette</Button>
        </CardActions>
      </Card>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {altRecipe.name}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            label="Nombre de portions"
            type="number"
            value={portions}
            onChange={handlePortionsChange}
            fullWidth
            margin="normal"
            InputProps={{ inputProps: { min: 1 } }} // Limiter les portions à un minimum de 1
          />
          <Typography variant="h6">Pour : {portions} portions</Typography>
          <Typography variant="h6">Ingredients</Typography>
          <Typography variant="body2" style={{ textAlign: 'left' }}>
            <ul>
              {altRecipe.ingredients.map((ingredient) => (
                <li key={ingredient}>
                  {ingredient}: {(altRecipe.quantites.details[ingredient] * portions).toFixed(3)} {altRecipe.quantites.unit[ingredient]}
                </li>
              ))}
            </ul>
          </Typography>
          <Typography variant="h6">Etapes</Typography>
          <Typography variant="body2" style={{ textAlign: 'left' }}>
            <ol>
              {altRecipe.etapes.map((etape, index) => (
                <li key={index}>{etape}</li>
              ))}
            </ol>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
