import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { addRecipeFile } from '../../utils/utils';  // Assurez-vous que le chemin est correct
import { useData, DataProvider } from '../../DataProvider';

const FileUploadButton = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const { restaurant, loadDataForRestaurant, client } = useData();
  const { accessToken } = useData();

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      setUploading(true);
      try {
        const response = await addRecipeFile(selectedFile, restaurant, accessToken ).then((response) => {
          if ('error' in response) {
            alert("Erreur lors de l'ajout des fiches techniques");
            return;
          }
          loadDataForRestaurant(restaurant, client);
          alert("Fiches techniques ajoutées avec succès");
        });
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        setUploading(false);
      }
    }
  };

  return (
    <div style={{ margin: 10 }}>
      <input
        accept="*"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="raised-button-file">
        <Button
          variant="contained"
          color="primary"
          component="span"
          disabled={uploading}
          startIcon={uploading ? <CircularProgress size={20} /> : null}
        >
          {uploading ? 'Ajout de la recette...' : 'Ajouter une fiche technique'}
        </Button>
      </label>
      <div>
        <a href="https://foodprint.blob.core.windows.net/foodprintblobs/foodprint_recipe_format(3).xlsx?sp=r&st=2024-09-03T11:42:25Z&se=2029-11-26T20:42:25Z&spr=https&sv=2022-11-02&sr=b&sig=30t9c8jN40R75mi%2FY3lJfsA9pjzw5bXu5wVmsgI8KTA%3D" target="_blank" rel="noopener noreferrer">
          Cliquer ici pour télécharger le format foodprint
        </a>
      </div>
    </div>
  );
};

export default FileUploadButton;
