export const packaging_list = [
    "Barquette en carton",
    "Boite en carton",
    "Brique en carton",
    "Film en papier",
    "Sachet en papier",
    "Bouteille en verre",
    "Pot en verre",
    "Barquette en PET",
    "Bouteille en PET",
    "Pot en PET",
    "Barquette en rPET",
    "Bouteille en rPET",
    "Barquette en PEHD",
    "Bouteille en PEHD",
    "Pot en PEHD",
    "Barquette en PVC",
    "Boite en PVC",
    "Pot en PVC",
    "Sachet en PVC",
    "Film en PVC",
    "Barquette en PEBD",
    "Pot en PEBD",
    "Bouteille en PEBD",
    "Barquette en PP",
    "Pot en PP",
    "Barquette en PS",
    "Pot en PS",
    "Bouteille en PLA",
    "Pot en PLA",
    "Film en PLA",
    "Canette en aluminium",
    "Film en aluminium",
    "Sachet plastique",
    "Film en plastique",
]