import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useData } from '../../DataProvider'; 

function createData(name, footprint, nutriscore) {
  return { name, footprint, nutriscore };
}

export default function TopRecipes() {
  const { data, prod_stats, restaurant, reports, listRestaurants, client, isLoading, error } = useData();
  if (isLoading) {
    return <div>Chargement...</div>;
  }
  if (error) {
    return <div>Erreur</div>;
  }
  if (data.length === 0) {
    return <div>Pas de données</div>;
  }

  var topRecipes = JSON.parse(JSON.stringify(data));
  topRecipes.sort((a, b) => a.Footprint/a.Nb_portion - b.Footprint/b.Nb_portion);
  topRecipes.splice(3, topRecipes.length - 3);
  const rows = topRecipes.map((recette) => {
    return createData(recette.Name, parseInt(recette.Footprint/recette.Nb_portion), recette.Nutriscore)
  })
  return (
    <TableContainer component={Paper} sx={{width:"100%", overflow:"hidden"}}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell >Recette</TableCell>
            <TableCell >gCO2e/pers</TableCell>
            <TableCell >Nutriscore</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
            >
              <TableCell component="th" scope="row" > {row.name}</TableCell>
              <TableCell >{row.footprint}</TableCell>
              <TableCell >
                <div>
                  <img src={`./nutriscore/${row.nutriscore}.svg`}
                            alt={row.nutriscore}
                        height="100%"
                        width="100%"/>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}