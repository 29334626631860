import React, { useState } from 'react';
import { TextField, Button, Paper, Select, Typography } from '@mui/material';
import IngredientsList from './IngredientsList'; // Votre composant personnalisé
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import { addrecipe } from '../../utils/utils';
import { useData } from '../../DataProvider';
import { packaging_list } from './packaging.js';
import { Checkbox, ListItemText } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

const getScoreColor = (score) => {
  if (score >= 80) {
    return 'green';
  } else if (score >= 50) {
    return 'orange';
  } else {
    return 'red';
  }
};

const RecipeForm = () => {
  const [ingredients, setIngredients] = useState([]);
  const [nbportion, setNbportion] = useState(0);
  const [packaging, setPackaging] = useState([]);
  const [saveToMyRecipes, setSaveToMyRecipes] = useState(false); // Nouvel état pour la checkbox
  const [recipeName, setRecipeName] = useState(''); // Nouvel état pour le nom de la recette
  const [loading, setLoading] = React.useState(false);
  const [recipeReady, setRecipeReady] = React.useState(false);
  const [recipe, setRecipe] = React.useState({ 'Nutriscore': 'C', 'Ecoscore': 'B', 'Footprint': 1050 });
  const [processingError, setProcessingError] = React.useState(false);
  const { accessToken, restaurant, client, loadDataForRestaurant } = useData();

  let ingredientsQuantityOK = ingredients.every(ingredient => ingredient.quantity !== '' && ingredient.quantity !== 0);
  let ingredientNameOK = ingredients.every(ingredient => ingredient.Name.length > 0);
  let inputRecipeOK = nbportion === 0 || ingredients.length === 0 || !ingredientsQuantityOK || !ingredientNameOK || (saveToMyRecipes && recipeName === '');

  const handleIngredientChange = (newIngredients) => {
    setIngredients(newIngredients);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    let recipe = {
      "Name": saveToMyRecipes ? recipeName : "", // Si la case est cochée, utilisez le nom
      "Type": "",
      "Nb_portion": nbportion,
      "Packaging": packaging,
      "Company": "",
      "restaurant": "",
      "Ingredients": ingredients,
      "Status": "valid",
    };

    let restaurantContainer  = "ecocalculateur";
    if(saveToMyRecipes){
      restaurantContainer = restaurant;
    }

    addrecipe(restaurantContainer, recipe, accessToken)
      .then((response) => {
        if (!response) {
          setProcessingError(true);
        } else {
          loadDataForRestaurant(restaurant,client);
          setProcessingError(false);
          setRecipeReady(true);
          setRecipe(response);
          setLoading(false);
        }
      });
  };

  const handleUpdate = (key, value) => {
    if (key === 'nbportion') {
      setNbportion(value);
    }
    if (key === 'Packaging') {
      setPackaging(value);
    }
  };

  if (recipeReady) {
    var scoreColor = getScoreColor(parseInt(recipe['ecosinglescore']));
  }

  return (
    <Box>
      <Paper sx={{ maxWidth: '100%', overflowX: 'auto', padding: "5px", display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
        <Typography variant="h6" style={{ marginBottom: 15 }}>Informations générales sur la recette</Typography>
        <form onSubmit={handleSubmit}>

          {/* Ajout de la checkbox au début du formulaire */}
          <FormControlLabel
            control={
              <Checkbox
                checked={saveToMyRecipes}
                onChange={(e) => setSaveToMyRecipes(e.target.checked)}
                color="primary"
              />
            }
            label="Enregistrer cette recette dans 'Mes recettes'"
          />

          {/* Afficher le champ pour le nom de la recette si la checkbox est cochée */}
          {saveToMyRecipes && (
            <div style={{ display: "flex", flexDirection: "column", marginTop: 15 }}>
              <Typography variant="h8" style={{ marginBottom: 5 }}>Nom de la recette<span style={{ color: "red" }}>*</span></Typography>
              <TextField
                value={recipeName}
                onChange={(e) => setRecipeName(e.target.value)}
                fullWidth
              />
            </div>
          )}

          <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-end', marginBottom: 30, marginTop: 10 }}>
            <div></div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h8" style={{ marginBottom: 5 }}>Nombre de portions<span style={{ color: "red" }}>*</span></Typography>
              <TextField
                  type="number"
                  value={nbportion}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if (value >= 0 || e.target.value === "") { // Accepter seulement les valeurs positives ou une entrée vide
                      handleUpdate('nbportion', e.target.value);
                    }
                  }}
                  inputProps={{ min: 0 }} // Utilisation de min pour bloquer les valeurs négatives
                  fullWidth
                />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h8" style={{ marginBottom: 5 }}>Emballage</Typography>
              <Select
                label="Emballage"
                multiple
                value={packaging}
                onChange={(e) => handleUpdate('Packaging', e.target.value)}
                renderValue={(selected) => selected.join(',\n')}
              >
                {packaging_list.map((pack) => (
                  <MenuItem key={pack} value={pack}>
                    <Checkbox checked={packaging.includes(pack)} />
                    <ListItemText primary={pack} />
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <Typography variant="h6" className='ingredientTitle'>Liste des ingrédients</Typography>
          <IngredientsList ingredients={ingredients} onIngredientsChange={handleIngredientChange} />
          <div style={{ textAlign: "center" }}>
            <Button disabled={inputRecipeOK || loading} type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
              Analyser cette recette
              {loading && (
                <>
                  <CircularProgress color='info' />
                </>
              )}
            </Button>
          </div>
        </form>
      </Paper>
      <Box style={{ marginTop: 20, textAlign: "center", alignContent: 'center' }}>
        {
          recipeReady && (
            <Alert severity="success" style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <AlertTitle>Analyse de la recette reussie !</AlertTitle>
              <h2>Vos indicateurs:</h2>
              <Box style={{ textAlign: "center" }}>
                <img src={`./nutriscore/${recipe['Nutriscore']}.svg`}
                  alt={`${recipe['Nutriscore']}`}
                  height="20%"
                  width="20%" />
                <img src={`./ecoscore/${recipe['Ecoscore']}.svg`}
                  alt={`${recipe['Ecoscore']}`}
                  height="8%"
                  width="8%" />
                <p><strong>Empreinte carbone de {parseInt(recipe['Footprint'] / recipe['Nb_portion'])}gCO2eq/portion</strong></p>
                <p><strong>{parseInt(recipe['Footprint'] / recipe['Nb_portion'])}gCO2eq équivaut à {parseInt((recipe['Footprint']) / recipe['Nb_portion'] / 85)} km en voiture</strong></p>
                <p><strong>Score ecologique <span style={{ color: scoreColor }}>{parseInt(recipe['ecosinglescore'])}/100</span></strong></p>
              </Box>
            </Alert>
          )
        }

        {
          processingError && (
            <Alert severity="error">
              <AlertTitle>Erreur lors de l'analyse de la recette!</AlertTitle>
              Une erreur est survenue à l'analyse de la recette<br />
              Re essayez, si cela ne fonctionne toujours pas contactez le support (rubrique Aide & Contact).
            </Alert>
          )
        }
      </Box>
    </Box>
  );
};

export default RecipeForm;
