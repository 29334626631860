import React, { useState } from 'react';
import { Box, Grid, Card, CardContent, CardMedia, Typography, Button, Chip, TextField } from '@mui/material';
import { useData } from '../../DataProvider';
import { Link } from 'react-router-dom';

const categories = [
  { id: 'support-de-communication', label: 'Support de communication', color: '#2196F3' },
  { id: 'reglementation', label: 'Réglementation', color: '#FF9800' },
  { id: 'guide-transition', label: 'Guide de la transition écologique', color: '#9C27B0' },
  { id: 'rh', label: 'Ressources humaines', color: '#FD6C9E' },
  { id: 'creation-menu', label: 'Création de menus', color: '#3F51B5' },
  { id: 'stock-approvisionnement', label: 'Gestion des stocks et approvisionnement', color: '#FFC107' },
  { id: 'managment', label: 'Management', color: '#FF5722' },
  { id: 'fiches-recettes', label: 'Fiches recettes', color: '#4CAF50' },
  { id: 'hygiene-securite', label: 'Hygiène & Sécurité', color: '#E91E63' },
  { id: 'equipement', label: 'Equipement', color: '#009688' },
  { id: 'recettes', label: 'Recettes', color: '#085FFF' }, // catégorie principale pour les recettes
];

async function downloadBlobToFile(blobName, url) {
  window.open(url, '_blank');
  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 10000);
}

const CategorySection = ({ category, resources }) => (
  <Box id={category.id} sx={{ mt: 4 }}>
    <Typography variant="h5" gutterBottom sx={{ color: category.color, borderBottom: `2px solid ${category.color}`, borderRadius: '4px' }}>
      {category.label}
    </Typography>
    <Grid container spacing={2}>
      {resources.slice(0, 4).map((resource, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card>
            <CardMedia
              component="img"
              height="200"
              image={resource.illustration_link}
              alt={resource.titre}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {resource.titre}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {resource.description}
              </Typography>
              <Box sx={{ mt: 2 }}>
                {resource.tags.map((tag, idx) => (
                  <Chip label={tag} key={idx} sx={{ mr: 1, mb: 1 }} />
                ))}
              </Box>
              <Button onClick={() => { downloadBlobToFile(resource.titre, resource.content_link) }} variant="outlined" sx={{ mt: 2, borderColor: category.color, color: category.color }}>
                Télécharger
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
    <Button
      component={Link} // Utiliser Link pour naviguer
      to={`/category/${category.id}`} // Définir le chemin de la nouvelle page
      variant="outlined"
      sx={{ mt: 2, borderColor: category.color, color: category.color }}
    >
      En voir plus
    </Button>
  </Box>
);

const Ressources = () => {
  const { ressources } = useData();
  const [searchTerm, setSearchTerm] = useState('');

  // Regrouper les ressources des sous-catégories 'recettes-entrée', 'recettes-plat', 'recettes-dessert' sous la catégorie principale 'recettes'
  const normalizedResources = ressources.map((resource) => ({
    ...resource,
    category: ['recettes-entree', 'recettes-plat', 'recettes-dessert'].includes(resource.category) ? 'recettes' : resource.category,
  }));

  const filteredResources = normalizedResources.filter((resource) =>
    resource.titre.toLowerCase().includes(searchTerm.toLowerCase()) ||
    resource.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
    resource.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const filteredCategories = categories.filter(category =>
    filteredResources.some(resource => resource.category === category.id)
  );

  return (
    <div className="myrecipes">
      <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src="logo_bis.svg" alt="logo_bis" />
        <h1 style={{ marginLeft: 10 }}>Ressources</h1>
      </Box>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
        <TextField
          label="Rechercher..."
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            marginTop: 3,
            width: '40%', // Définir la largeur ici, 2 fois plus longue que par défaut
            borderRadius: 2,
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
            },
          }}
        />
      </Box>
      <Box sx={{ mb: 4 }}>
        {filteredCategories.map((category) => (
          <Button
            key={category.id}
            href={`#${category.id}`}
            variant="outlined"
            sx={{ mr: 2, mb: 2, borderColor: category.color, color: category.color }}
          >
            {category.label}
          </Button>
        ))}
      </Box>
      {filteredCategories.map((category) => (
        <CategorySection
          key={category.id}
          category={category}
          resources={filteredResources.filter((resource) => resource.category === category.id)}
        />
      ))}
    </div>
  );
}

export default Ressources;
