import * as React from 'react';
import {PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { Box } from '@mui/material';

export default function RatiosCharts({stats}) {
  const valueFormatter = (item) => {
    return `${item.value}%`;
  }
  let data_object_formatted = Object.keys(stats).map((key, index) => {
    return { id: index, value: stats[key], label: key }
  })
  
  const [data, setData] = React.useState([
    {
      highlightScope: { faded: 'global', highlighted: 'item' },
      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
      arcLabel: (item) => {
        if(item.value < 7){
          return "";
        }
        if(item.value < 11){
          return `${item.label}`;
        }
        return `${item.label} (${item.value} %)`},
      arcLabelMinAngle: 0,
      data: data_object_formatted,
      valueFormatter
    },
  ]);

  return (
    <Box sx={{height:"80%"}}>
      <PieChart
          series={data}
          labelKey="label"
          sx={{
            paddingLeft:12,
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontWeight: 'bold',
              fontSize: '0.8em',
            },
          }}
          slotProps={{
            legend: { hidden: true },
          }}
        />
    </Box>

  );
}