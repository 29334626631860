import React, { useEffect } from 'react';
import './EcoInfos.css';
import Box from '@mui/material/Box';
//import image


function EcoInfos(){
  
  // Obtenir la date actuelle
  const today = new Date();
  // Convertir la date en nombre (timestamp)
  const timestamp = today.getTime();
  // Utiliser une fonction de hachage simple pour normaliser le nombre
  const randomNumber = Math.abs(Math.sin(timestamp)) * 40;
  // Arrondir et convertir en entier entre 1 et 40
  const number = Math.floor(randomNumber) % 40 + 1;

  let sas = "sv=2022-11-02&ss=bfqt&srt=co&sp=rlpitfx&se=2028-12-05T20:25:07Z&st=2024-06-14T11:25:07Z&spr=https&sig=WDpHPlOsVO5qMwC03LLCAhFnDd6nrtMzi3fJOcCtTXc%3D"
  let img_url= `https://foodprint.blob.core.windows.net/ecoinfos/${number}.png?${sas}`

  // Créer les composants Autocomplete
  return(
    <div className='menuentry'>
    <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <img src="logo_bis.svg" alt="logo_bis" />
      <h1 style={{ marginLeft: 10 }}>Information du jour</h1>
    </Box>
    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: 1, marginRight: 1 }}>
      <img src={img_url} alt="eco_infos" width={"75%"}/>
    </Box>
    </div>
    )

}

export default EcoInfos;
