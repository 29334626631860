import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

export default function LineChartMonths({stats}) {
  let previous_footprint = stats["Last_6months_footprint"];
  // s'assurer que l'on prend les 5 derniers
  if (previous_footprint.length > 5) {
    previous_footprint = previous_footprint.slice(-5);
  }
  let current_footprint = stats["Average_footprint"];

  // divide by 1000 to convert g to kg
  previous_footprint = previous_footprint.map((footprint) => footprint/1000);
  // Ajouter le footprint actuel à la liste des footprints
  previous_footprint.push(current_footprint/1000);
  //Créer la liste de mois à partir du mois actuel en francais, les 6 derniers mois
  let date = new Date();
  let month = date.getMonth();
  let year = date.getFullYear();
  let xLabels = [];
  for (let i = 0; i < 6; i++) {
    xLabels.push(new Intl.DateTimeFormat('fr-FR', { month: 'long' }).format(new Date(year, month - i )));
  }

  //reverse xLabels
  xLabels.reverse();

  return (
    <LineChart
      series={[
        { data: previous_footprint, label: 'kg co2eq/portion moyen' },
      ]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
      yAxis={[
        {
          scaleType: 'linear',
          domain: [0, 1], // Définir le domaine de l'axe des y entre 0 et 1
        },
      ]}
    />
  );
}
