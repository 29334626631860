import React from 'react';
import './directory.css'; // Votre CSS pour le tableau de bord
import EnhancedTable from './TableDirectory';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useData } from '../../DataProvider';

const Directory = () => {
  let navigate = useNavigate();
  const { data, prod_stats, reports, restaurant, listRestaurants, client, isLoading, error } = useData();
  if(isLoading){
    return(
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center' }} >
          <h1>Chargement des données</h1>
          <CircularProgress />
        </Box>
      </div>
    )
  }
  else if(error){
    /*return (
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center', verticalAlign: 'center' }} >
        <Alert severity="error" sx={{verticalAlign:'center'}}>
          <AlertTitle>Erreur</AlertTitle>
            Une erreur est survenue au chargement des données de votre restaurant. <br/>
            Rafraichissez la page, si cela ne fonctionne toujours pas, contactez le support (rubrique Aide & Contact).
        </Alert>
        </Box>
      </div>
    )*/
  }

  return (
    
    <div className="myrecipes">
    <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
      <img src="logo_bis.svg" alt="logo_bis" />
      <h1 style={{marginLeft:10}}>Annuaire prestataires</h1>
    </Box>
    <EnhancedTable/>

    </div>
  );
};

export default Directory;