import React, { useState, useContext } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useData } from '../../DataProvider'; // Assurez-vous de spécifier le bon chemin

const RestaurantSelector = () => {
  // État pour stocker le restaurant sélectionné
  const { data, prod_stats, reports, restaurant, listRestaurants, client, isLoading, error, loadDataForRestaurant } = useData();
  const [selectedRestaurant, setSelectedRestaurant] = useState(null);

  // Accédez au contexte 'data' avec useData()
  // Gérez le changement de restaurant sélectionné
  const handleRestaurantChange = (event) => {
    const newRestaurant = event.target.value;
    setSelectedRestaurant(newRestaurant);
    loadDataForRestaurant(newRestaurant, client);
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }//
  return (
    <FormControl style={{marginTop:"10%", marginLeft:"7%", marginRight:"7%"}}>
      <InputLabel
        id="restaurant-select-label"
        sx={{ color: 'white' }} // Texte en blanc
      >
        Choisissez un restaurant
      </InputLabel>
      <Select
        labelId="restaurant-select-label"
        id="restaurant-select"
        value={selectedRestaurant ? selectedRestaurant : restaurant}
        label="Choisissez un restaurant"
        onChange={handleRestaurantChange}
        sx={{
          color: 'white', // Texte en blanc
          '&.Mui-focused': {
            color: 'white', // Texte en blanc quand le Select est focus
          },
          '& fieldset': {
            borderColor: 'white', // Contour en blanc
          },
        }}
      >
        {listRestaurants.map((restaurant) => (
          <MenuItem key={restaurant} value={restaurant}>
            {restaurant}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RestaurantSelector;
