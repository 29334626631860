import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { Box } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';

export default function RatiosCharts({stats}) {
  const valueFormatter = (item) => {
    return `${item.value}%`;
  }
  const [ratio, setRatio] = React.useState('ratios');
  const [data, setData] = React.useState([
    {
      highlightScope: { faded: 'global', highlighted: 'item' },
      faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
      arcLabel: (item) => {
        if(item.value < 7){
          return "";
        }
        if(item.value < 11){
          return `${item.label}`;
        }
        return `${item.label} (${item.value} %)`},
      arcLabelMinAngle: 0,
      data: [
        { id: 0, value: stats["bio"], label: 'Bio' },
        { id: 1, value: stats["siqo"], label: 'SIQO' },
        { id: 2, value: stats["conventionel"], label: 'Conventionel' },

    ],
    valueFormatter
    },
  ]);
  const handleChange = (event, newRatio) => {
    if (newRatio !== null) { // Check if a new value is selected
      if(newRatio==="ratios"){
        setData([
          {
            highlightScope: { faded: 'global', highlighted: 'item' },
            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            arcLabel: (item) => {
              if(item.value < 7){
                return "";
              }
              if(item.value < 11){
                return `${item.label}`;
              }
              return `${item.label} (${item.value} %)`
            },
            arcLabelMinAngle: 0,
            data: [
              { id: 0, value: stats["bio"], label: 'Bio' },
              { id: 1, value: stats["siqo"], label: 'SIQO' },
              { id: 2, value: stats["conventionel"], label: 'Conventionel' },
      
          ],
          valueFormatter
          },
        ]);
      }else if(newRatio==="labels"){
        setData(stats["Label_part"]);
      }

    setRatio(newRatio);
  }

  };

  
  const getChart = (ratio) => {
    if(ratio==="ratios"){
      return (
        <PieChart
          series={data}
          labelKey="label"
          sx={{
            paddingLeft:12,
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontWeight: 'bold',
              fontSize: '0.8em',
            },
          }}
          slotProps={{
            legend: { hidden: true },
          }}
        />
      )
    }
    else if(ratio==="labels"){
      //x is list of keys of data
      let x = Object.keys(data);
      let y = Object.values(data);
      y = y.map((item) => item*100);
      return (
        <BarChart
        xAxis={[{ scaleType: 'band', data: x }]}
        series={[{ type: 'bar', data: y }]}
        />
      )
    }
  }

  return (
    
    <Box sx={{height:"65%"}}>
      <ToggleButtonGroup
        color="primary"
        value={ratio}
        exclusive={true}
        onChange={handleChange}
        aria-label="Ratio"
        style={{marginBottom:15}}
      >
        <ToggleButton value="ratios">Ratios EGalim</ToggleButton>
        <ToggleButton value="labels">Labels</ToggleButton>
      </ToggleButtonGroup>

      {getChart(ratio)}

    </Box>
  );
}