import React, { useEffect } from 'react';
import './welcomeview.css'; 
import Box from '@mui/material/Box';
import { useData } from '../../DataProvider'; 
import { create_new_user } from '../../utils/utils';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
/**
 * Cette page est atteinte lorsque l'utilisateur vient de s'inscrire
 * Elle lance l'azure fonction pour faire les vérification nécéssaires et créer les containers si nécéssaire
 * L'azure fonction retourne les messages sà afficher
 * @returns 
 */

const getLoading = () => {
  return (
      <Box component="div" sx={{ display: 'column', alignItems: 'center', justifyContent: 'center' }} >
        <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src="logo_bis.svg" alt="logo_bis" />
            <h1>Paramètrage de votre espace MyFoodPrint...</h1>
        </Box>
        <CircularProgress />
      </Box>
  );
}
const getError = () => {
  return (
      <Box component="div" sx={{ display: 'column', alignItems: 'center', justifyContent: 'center' }} >
        <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src="logo_bis.svg" alt="logo_bis" />
            <h1>Une erreur est survenue lors de la création de votre espace MyFoodPrint</h1>
        </Box>
        <h3>Veuillez contacter mathias.bossaerts@foodprint.eco</h3>
      </Box>
  );
}
const getExistingContainer = () => {
  return (
      <Box component="div" sx={{ display: 'column', alignItems: 'center', justifyContent: 'center' }} >
      <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src="logo_bis.svg" alt="logo_bis" />
          <h1>Un compte pour ce restaurant existe déjà</h1>
          </Box>
        <h3>Si vous souhaitez ajouter un nouveau compte pour ce restaurant, veuillez contacter mathias.bossaerts@foodprint.eco</h3>
      </Box>
  );
}

const getSuccess = () => {
  return (
      <Box component="div" sx={{ display: 'column', alignItems: 'center', justifyContent: 'center' }} >
        <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src="logo_bis.svg" alt="logo_bis" />
          <h1 style={{ marginLeft: 10 }}>Merci de votre inscription à MyFoodPrint!</h1>
        </Box>
        <h1>Votre espace MyFoodPrint est prêt à être utilisé</h1>
        <h3>Afin de profiter pleinement de la plateforme, contactez elisa.jourde@foodprint.eco</h3>
      </Box>
  );
}

const WelcomeView = () => {
  const {restaurant, accessToken, loadDataForRestaurant, setnewUser, client, gestionnaire } = useData();
  const [mode, setMode] = useState("chargement");
  useEffect(() => {
    create_new_user(restaurant, gestionnaire, accessToken).then(data => {
      if(data.containerexists){
        //setMode("containerexists");
        setMode("newcontainer");
        loadDataForRestaurant(restaurant, client);
        setnewUser(false);

      }
      else if(data.status === 501){
        setMode("error");
      }
      else{
        setMode("newcontainer");
        loadDataForRestaurant(restaurant, client);
        setnewUser(false);
      }
    });
  }, []);
  return (
    <div className="myrecipes">
      <Box component="div" sx={{ display: 'column', alignItems: 'center', justifyContent: 'center' }}>

        {
          mode === "chargement" ? getLoading() :
          mode === "containerexists" ? getExistingContainer() :
          mode === "newcontainer" ? getSuccess(): getError()
        }
      </Box>
    </div>
  );
};

export default WelcomeView;