import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Box } from '@mui/material';

const RatiosChartsIndicators = ({stats, type}) => {
  let data = Object.keys(stats).map((key, index) => {
    return { id: key, value: stats[key] }
  })

  const colors = ['#1E8F4E', '#2ECC71', '#F5C100', '#EF7E1A', '#D93726'];

  return (
    <Box sx={{height:"80%", width:"100%"}}>
      <ResponsiveBar
        data={data}
        keys={['value']}
        indexBy="id"
        margin={{ top: 50, right: 0, bottom: 50, left: 60 }}
        padding={0.3}
        colors={({ index }) => colors[index]}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: type,
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Nombre de recettes',
          legendPosition: 'middle',
          legendOffset: -45,
        }}
      />
    </Box>
  );
};

export default RatiosChartsIndicators;
