import React from 'react';
import './addrecipes.css'; // Votre CSS pour le tableau de bord
import RecipeForm from './RecipeForm';
import { Box } from '@mui/material';
/*
- nom de la recette
- type de plat (entrée, plat, dessert)
- nombre de portions
- ingrédients : 
  - nom
  - quantité
  - unité de mesure
  - label (optionnel)
  - provenance
  - cuisson
  - emballage
  - conservation
  */
const AddRecipes = () => {
  return (
    <div className="addrecipes">
    <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
      <img src="logo_bis.svg" alt="logo_bis" />
      <h1 style={{marginLeft:10}}>Ajouter une recette</h1>
    </Box>
      <RecipeForm />
    </div>
  );
};

export default AddRecipes;