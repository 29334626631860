// components/CategoryPage/CategoryPage.js
import React, { useState, useEffect } from 'react'; // Ajoutez useEffect ici
import { useParams } from 'react-router-dom';
import { Box, Grid, Card, CardContent, CardMedia, Typography, Button, Chip } from '@mui/material';
import { useData } from '../../DataProvider';

const categories = [
  { id: 'support-de-communication', label: 'Support de communication', color: '#2196F3' },
  { id: 'reglementation', label: 'Réglementation', color: '#FF9800' },
  { id: 'guide-transition', label: 'Guide de la transition écologique', color: '#9C27B0' },
  { id: 'rh', label: 'Ressources humaines', color: '#FD6C9E' },
  { id: 'creation-menu', label: 'Création de menus', color: '#3F51B5' },
  { id: 'stock-approvisionnement', label: 'Gestion des stocks et approvisionnement', color: '#FFC107' },
  { id: 'managment', label: 'Management', color: '#FF5722' },
  { id: 'fiches-recettes', label: 'Fiches recettes', color: '#4CAF50' },
  { id: 'hygiene-securite', label: 'Hygiène & Sécurité', color: '#E91E63' },
  { id: 'equipement', label: 'Equipement', color: '#009688' },
  { id: 'recettes', label: 'Recettes', color: '#085FFF' }, // catégorie principale pour les recettes
];

const subcategories = [
  { id: 'recettes-entree', label: 'Entrée' },
  { id: 'recettes-plat', label: 'Plat' },
  { id: 'recettes-dessert', label: 'Dessert' },
];

const CategoryPage = () => {
  const { ressources } = useData();
  const { categoryId } = useParams(); // Récupère le paramètre de catégorie depuis l'URL
  const [selectedSubcategory, setSelectedSubcategory] = useState(''); // État pour la sous-catégorie sélectionnée

  // Utiliser useEffect pour définir la sous-catégorie par défaut
  useEffect(() => {
    if (categoryId === 'recettes') {
      setSelectedSubcategory('recettes-plat'); // Définir "plat" par défaut pour la catégorie "recettes"
    }
  }, [categoryId]);

  // Trouver la catégorie sélectionnée pour utiliser les couleurs et le titre
  const selectedCategory = categories.find((category) => category.id === categoryId);

  // Filtrer les ressources pour la catégorie sélectionnée et la sous-catégorie
  const categoryResources = ressources.filter(
    (resource) =>
      resource.category === categoryId ||
      (categoryId === 'recettes' && 
        (!selectedSubcategory || resource.category === selectedSubcategory))
  );

  async function downloadBlobToFile(blobName, url) {
    window.open(url, '_blank');
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 10000);
  }

  return (
    <div className="myrecipes">
      <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src="../logo_bis.svg" alt="logo_bis" />
        <h1 style={{ marginLeft: 10 }}>{selectedCategory?.label}</h1>
      </Box>

      {/* Afficher les sous-catégories si la catégorie est "Recettes" */}
      {categoryId === 'recettes' && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          {subcategories.map((subcategory) => (
            <Button
              key={subcategory.id}
              variant={selectedSubcategory === subcategory.id ? 'contained' : 'outlined'}
              onClick={() => setSelectedSubcategory(subcategory.id)}
              sx={{ mx: 1 }}
            >
              {subcategory.label}
            </Button>
          ))}
        </Box>
      )}

      <Box sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          {categoryResources.map((resource, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card>
                <CardMedia
                  component="img"
                  height="200"
                  image={resource.illustration_link}
                  alt={resource.titre}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {resource.titre}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {resource.description}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    {resource.tags.map((tag, idx) => (
                      <Chip label={tag} key={idx} sx={{ mr: 1, mb: 1 }} />
                    ))}
                  </Box>
                  <Button
                    onClick={() => downloadBlobToFile(resource.titre, resource.content_link)}
                    variant="outlined"
                    sx={{ mt: 2, borderColor: selectedCategory?.color, color: selectedCategory?.color }}
                  >
                    Télécharger
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default CategoryPage;
