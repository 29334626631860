import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../DataProvider';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

function createData(index, nomAide, description, montant, source, type, eligibilite, exemplesProjets, lien, deadline) {
  return {
    id: index,
    nomAide,
    description,
    montant,
    source,
    type,
    eligibilite,
    exemplesProjets,
    lien,
    deadline
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'nomAide',
    numeric: false,
    disablePadding: false,
    label: 'Nom de l\'Aide',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'montant',
    numeric: false,
    disablePadding: false,
    label: 'Montant',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

function EnhancedTableToolbar(props) {
  const { handleSearchChange } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        justifyContent: 'center',
      }}
    >
      <TextField
        label="Rechercher une subvention"
        variant="outlined"
        onChange={handleSearchChange}
        sx={{ 
          marginTop: 3,
          width: '40%',
          borderRadius: 2,
          '& .MuiOutlinedInput-root': {
            borderRadius: '25px',
          },
        }}
      />
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  handleSearchChange: PropTypes.func.isRequired,
};

export default function EnhancedTable() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('nomAide');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const { subventions, isLoading } = useData();
  const [searchTerm, setSearchTerm] = React.useState('');
  let navigate = useNavigate();

  const visibleRows = React.useMemo(() => {
    if (!rows) {
      return [];
    }
    const filteredRows = rows.filter((row) =>
      row.nomAide.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return stableSort(filteredRows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [order, orderBy, page, rowsPerPage, rows, searchTerm]);

  React.useEffect(() => {
    if (subventions) {
      const new_rows = subventions.map((p, index) => 
        createData(index, p["Nom de l'Aide"], p.Description, p.Montant, p.Source, p.Type, p.Eligibilité, p["Exemples de Projets Finançables"], p.Lien, p.Deadline)
      );
      setRows(new_rows);
      setOrderBy('nomAide');
      setOrder('asc');
    }
  }, [subventions]);

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleActionSee = (event, index) => {
    const selectedRow = rows.find((row) => row.id === index);
    setSelectedRow(selectedRow);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
  <Paper sx={{ width: '100%', mb: 2 }}>
    <EnhancedTableToolbar handleSearchChange={handleSearchChange} />
    <TableContainer>
      <Table
        sx={{ minWidth: 750 }}
        aria-labelledby="tableTitle"
        size={'medium'}
      >
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
        />
        <TableBody>
          {visibleRows.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, row.id)}
                tabIndex={-1}
                key={row.id}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell align="left">{row.nomAide}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
                <TableCell align="left">{row.montant}</TableCell>
                <TableCell align="left">
                  <Button onClick={(event) => handleActionSee(event, row.id)} variant="contained" style={{ backgroundColor: '#38A48E', color: '#FFFFFF', borderRadius: 10, marginTop: "2%" }}>Détails</Button>
                </TableCell>
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Paper>

  <Dialog open={open} onClose={handleClose}>
    <DialogTitle>Détails de la subvention</DialogTitle>
    <DialogContent>
      {selectedRow && (
        <div>
          <DialogContentText>Nom: {selectedRow.nomAide}</DialogContentText>
          <DialogContentText>Description: {selectedRow.description}</DialogContentText>
          <DialogContentText>Montant: {selectedRow.montant}</DialogContentText>
          <DialogContentText>Source: {selectedRow.source}</DialogContentText>
          <DialogContentText>Type: {selectedRow.type}</DialogContentText>
          <DialogContentText>Éligibilité: {selectedRow.eligibilite}</DialogContentText>
          <DialogContentText>Exemples de Projets Finançables: {selectedRow.exemplesProjets}</DialogContentText>
          <DialogContentText>Lien: <a href={selectedRow.lien} target="_blank" rel="noopener noreferrer">{selectedRow.lien}</a></DialogContentText>
          <DialogContentText>Deadline: {selectedRow.deadline}</DialogContentText>
        </div>
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Fermer
      </Button>
    </DialogActions>
  </Dialog>
</Box>
  );
} 
