import React from 'react';
import { Link } from 'react-router-dom';
import './sidebar.css'; // Votre CSS pour la sidebar
import { FaHome, FaUtensils, FaCalculator, FaInfoCircle } from 'react-icons/fa'; // Importez les icônes que vous souhaitez utiliser
import { BiSolidFoodMenu } from "react-icons/bi";
import { IoIosHelpBuoy } from "react-icons/io";
import RestaurantSelector from './RestaurantSelector';
import { Box, Button } from '@mui/material';
import { useData } from '../../DataProvider';
import { RiContactsFill } from "react-icons/ri";
import { useMsal } from '@azure/msal-react';
import { GoFileDirectory } from "react-icons/go";
import { FaBookBookmark } from "react-icons/fa6";
import { SiCodechef } from "react-icons/si";
import { FaRegMoneyBill1 } from "react-icons/fa6";
import { PiCookingPotFill } from "react-icons/pi";

const Sidebar = () => {
  const { data, prod_stats, reports, restaurant, listRestaurants, client, isLoading, error, fonctionnalites } = useData();
  const { instance } = useMsal();

  const Logout = async () => {
    try {
        await instance.logoutPopup();
    } catch (error) {
        console.error(error);
    }
  }

  // Fonctionnalités disponibles
  const all_fonctionnalites = {
    "board": { path: "/", icon: <FaHome className="sidebar-icon" />, text: "Tableau de bord" },
    "saisiemenu": { path: "/menuentry", icon: <FaUtensils className="sidebar-icon" />, text: "Menu & Affichage" },
    "myrecipes": { path: "/myrecipes", icon: <BiSolidFoodMenu className="sidebar-icon" />, text: "Mes recettes" },
    "ecocalculateur": { path: "/ecocalculateur", icon: <FaCalculator className="sidebar-icon" />, text: "Eco-Calculateur" },
    "chef": { path: "/generatenewrecipe", icon: <SiCodechef className="sidebar-icon" />, text: "Chef Assistant" },
    "presta": { path: "/directory", icon: <RiContactsFill className="sidebar-icon" />, text: "Annuaire prestataires" },
    "subventions": { path: "/directorySubventions", icon: <FaRegMoneyBill1 className="sidebar-icon" />, text: "Annuaire Subventions" },
    "ressources": { path: "/ressources", icon: <FaBookBookmark className="sidebar-icon" />, text: "Ressources" },
    "faq": { path: "/help", icon: <IoIosHelpBuoy className="sidebar-icon" />, text: "FAQ" }
  };

  return (
    <div className="sidebar">
      <div className='sidebar-content'> 
        <div className="sidebar-logo">
          <img
            src="logo_foodprint_secondaire.svg"
            alt="logo foodprint"
            height="350"
            width="500"/>
        </div>
        <div className="sidebar-menu">
          {Object.keys(all_fonctionnalites).map(fonc => (
            fonctionnalites.includes(fonc) && (
              <Link key={fonc} to={all_fonctionnalites[fonc].path}>
                {all_fonctionnalites[fonc].icon}
                {all_fonctionnalites[fonc].text}
              </Link>
            )
          ))}
          <RestaurantSelector />
          <Button
            variant="secondary"
            className="ml-auto"
            title="Sign Out"
            onClick={Logout}
          >
            Se deconnecter
          </Button>
          <br/>
          <Box sx={{marginTop:"10%"}}>© 2024 Foodprint</Box> 
        </div>

      </div>
    </div>
  );
};

export default Sidebar;
