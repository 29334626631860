import React, { useState } from 'react';
import './myrecipes.css'; // Votre CSS pour le tableau de bord
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useData } from '../../DataProvider';
import InputAdornment from '@mui/material/InputAdornment';

const CSRD = () => {
  let navigate = useNavigate();

  const exportAll = () => {
    //TODO : LAunch azure function in order to generate report for all recipes
    //(in post body)
  };

  const { data, prod_stats, reports, restaurant, listRestaurants, client, isLoading, error } = useData();

  const [period, setPeriod] = useState('year');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [kwh, setKwh] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [result, setResult] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    }
  };

  const handleProcess = () => {
    setIsProcessing(true);
    setTimeout(() => {
      setResult(`Pour la période, l'impact carbone de vos achats alimentaires est de 1256 kg co2eq`);
      setIsProcessing(false);
    }, 10000);
  };

  if (isLoading) {
    return (
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
          <h1>Chargement des données</h1>
          <CircularProgress />
        </Box>
      </div>
    );
  } else if (error) {
    /*return (
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', verticalAlign: 'center' }} >
        <Alert severity="error" sx={{verticalAlign:'center'}}>
          <AlertTitle>Erreur</AlertTitle>
            Une erreur est survenue au chargement des données de votre restaurant. <br/>
            Rafraichissez la page, si cela ne fonctionne toujours pas, contactez le support (rubrique Aide & Contact).
        </Alert>
        </Box>
      </div>
    )*/
  }

  return (
    <div className="myrecipes">
      <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src="logo_bis.svg" alt="logo_bis" />
        <h1 style={{ marginLeft: 10 }}>CSRD : Bilan carbone alimentaire</h1>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <label>Période:</label>
          <TextField
            select
            value={period}
            onChange={(e) => setPeriod(e.target.value)}
            sx={{ marginLeft: '10px' }}
          >
            <MenuItem value="year">Année</MenuItem>
            <MenuItem value="month">Mois</MenuItem>
          </TextField>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <label>Importer fichier achats alimentaires:</label>
          <Button
            variant="contained"
            component="label"
            sx={{ marginLeft: '10px', backgroundColor: fileName ? 'green' : undefined }}
          >
            {fileName ? `${fileName} importé` : 'Importer'}
            <input
              type="file"
              hidden
              onChange={handleFileChange}
            />
          </Button>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <label>Consommation électrique:</label>
          <TextField
            value={kwh}
            onChange={(e) => setKwh(e.target.value)}
            type="number"
            inputProps={{ step: '0.01' }}
            InputProps={{
              endAdornment: <InputAdornment position="end">kWh</InputAdornment>,
            }}
            sx={{ marginLeft: '10px' }}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <Button
            variant="contained"
            onClick={handleProcess}
            disabled={isProcessing}
          >
            Lancer le traitement
          </Button>
        </Box>

        {isProcessing && <CircularProgress />}

        {result && (
          <Box
            sx={{
              marginTop: '20px',
              padding: '10px',
              backgroundColor: '#E5F6FD',
              color: '#013E64',
              borderRadius: '5px',
              textAlign: 'center',
            }}
          >
            {result}
          </Box>
        )}
      </Box>
    </div>
  );
};

export default CSRD;
