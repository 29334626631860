import React from 'react';
import { useLocation } from 'react-router-dom';
import "@fontsource/alegreya-sans"; // Defaults to weight 400
import { Box, Breadcrumbs, Container, Unstable_Grid2 as Grid } from '@mui/material';
import ProgressBarWithMagnifier from '../Dashboard/ProgressBarWithMagnifier/ProgressBarWithMagnifier';
import RatiosCharts from '../Dashboard/RatiosCharts';
import ReportTable from '../Dashboard/reportTable';
import TopRecipes from '../Dashboard/TopRecipes';
import RatiosChartsIndicators from '../Dashboard/RatiosChartsIndicators';
import LineChartMonths from '../Dashboard/LineChartMonths';
import styled from '@mui/material/styles/styled';
import Paper from '@mui/material/Paper';
import './RestaurantView.css';
import { fake_stats } from './fake_stats';

function prepareStats(stats){
  //TODO : cas où les stats sont vides
  if(!stats["Label_quantities"]){
    return false;
  }
  // siqo = sum of all except bio
  stats["siqo"] = 0;
  for (let key in stats["Label_quantities"]) {
    if(key !== "bio"){
      stats["siqo"] += parseInt(stats["Label_quantities"][key]*100);
    }
  }
  stats["bio"] = parseInt(stats["Label_quantities"]["bio"]*100);
  stats["conventionel"] = parseInt(100 - stats["bio"] - stats["siqo"]);

  return stats;
}

const RestaurantView = () => {
  let fake_stats_prepared = prepareStats(fake_stats);
  let loc = useLocation()
  let restaurant = loc.state.restaurant
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const getColor = (value) => {
    if(value >= 80){
      return '#38A48E';
    }
    else if(value >= 60){
      return '#FFD700';
    }
    else if(value >= 40){
      return '#FFA500';
    }
    else if(value >= 20){
      return '#FF6347';
    }
    else{
      return '#FF6347';
    }
  
    }

  return (
    <div className="dashboard">

        <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }} >
            <img src="../logo_bis.svg" alt="logo_bis" />
            <h1 style={{marginLeft:10}}> {restaurant} </h1>
        </Box>

    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {/* Première ligne */}
              <Grid item xs={2} sm={4} md={4} >
                <Item sx={{height:"100%", width:"100%"}}>
                  <h3>Rapports de production</h3>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <ReportTable reports_date={["prod-2024-03", "prod-2024-04", "prod-2024-05"] } restaurant={"foodprint_restaurant"}/>
                  </Box>
                </Item>
              </Grid>
  
              <Grid item xs={2} sm={4} md={4} >
                <Item sx={{height:"100%"}}>
                  <h3>Les ratios EGalim</h3>
                  <RatiosCharts stats={fake_stats_prepared}/>
                  
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4} >
                <Item sx={{height:"100%", width:"100%"}}>
                  <h3>Repartition des indicateurs</h3>
                  <RatiosChartsIndicators stats={fake_stats_prepared}/>
                </Item>
              </Grid>
  
              {/* Deuxième ligne */}
              <Grid item xs={2} sm={4} md={4}>
                <Item sx={{width: '100%'}}>
                  <h3>Impact carbone moyen</h3>
                  <h4 className="carbonImpact">5.3kg de CO2eq/portion en moyenne</h4>
                  <ProgressBarWithMagnifier value={50} maxValue={100} />
                  <h4 className='carbonGoal'>Mon objectif : 4.6kg de CO2eq/portion en moyenne</h4>
                </Item>
  
              </Grid>
              <Grid item xs={2} sm={4} md={4} sx={{maxHeight:375, }}>
                <Item sx={{height:"100%", paddingBottom:"10%"}}>
                  <h3>Evolution de l'impact carbone moyen</h3>
                  <LineChartMonths stats={fake_stats_prepared}/>
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4} >
                <Item>
                    <h3>Informations</h3> 
                    <Box component="div" sx={{ textAlign: 'left' }} >
                        <b>Nom du restaurant :</b> {restaurant}<br/>
                        <b>Adresse :</b> 12 rue des fleurs<br/>
                        <b>Ville :</b> Paris<br/>
                        <b>Code postal :</b> 75000<br/>
                        <b>Téléphone :</b> 01 23 45 67 89<br/>
                        <b>Score nutritionnel :</b> <span style={{color:getColor(79)}}>79/100</span><br/>
                        <b>Score environnemental :</b> <span style={{color:getColor(53)}}>53/100</span> <br/>
                    </Box>
                </Item>
              </Grid>
            </Grid>
    </div>
  );
};

export default RestaurantView;