import React from 'react';
import "@fontsource/alegreya-sans"; // Defaults to weight 400
import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import './RecipesReport.css';
import RatiosCharts from './RatiosCharts';
import RatiosChartsIndicators from './RatiosChartsIndicators';
import styled from '@mui/material/styles/styled';
import Paper from '@mui/material/Paper';
import { useData } from '../../DataProvider';
import CircularProgress from '@mui/material/CircularProgress';
import { Alert, AlertTitle } from '@mui/material';
import TopRecipes from './TopRecipes';

const Dashboard = () => {

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  //Data provider
  const { data, prod_stats, reports, restaurant, listRestaurants, client, isLoading, error } = useData();
  if(isLoading){
    return(
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center' }} >
          <h1>Chargement des données</h1>
          <CircularProgress />
        </Box>
      </div>
    )
  }
  else if(error){
    return (
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center', verticalAlign: 'center' }} >
        <Alert severity="error" sx={{verticalAlign:'center'}}>
          <AlertTitle>Erreur</AlertTitle>
            Une erreur est survenue au chargement des données de votre restaurant. <br/>
            Rafraichissez la page, si cela ne fonctionne toujours pas, contactez le support (rubrique Aide & Contact).
        </Alert>
        </Box>
      </div>
    )
  }
  else if(Object.keys(prod_stats).length === 0){
    return (
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center', verticalAlign: 'center' }} >
        <Alert severity="info" sx={{verticalAlign:'center'}}>
          <AlertTitle>Information</AlertTitle>
            Vous n'avez pas encore de données à afficher pour ce mois ci. <br/>
            Veuillez saisir vos menus.
        </Alert>
        </Box>
      </div>
    )
  }
  else {
    let stats = {
       "mean": 1500,
        "median": 1200,
        "nb_recipes": 3500,
        "mean_ecoscore": "C",
        "mean_nutriscore": "B",
        "distributions": {
          "ecoscore" : {
            "A": 250,
            "B": 1000,
            "C": 1800,
            "D": 350,
            "E": 200
          },
          "nutriscore" : {
              "A": 500,
              "B": 1500,
              "C": 1200,
              "D": 100,
              "E": 350
            },
            "carbon": {
              "0-500": 500,
              "500-1000": 1000,
              "1000-1500": 800,
              "1500-2000": 500,
              "2000-2500": 200
            },
          },
          "Label_part": {
            "Bio": 20,
            "SIQO": 30,
            "Conventionel": 50
          },
          "Provenance_part": {
            "France": 30,
            "Europe": 60,
            "Hors Europe": 10
          }
    }
    return (
      <div className="dashboard">
      <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }} >
        <img src="logo_bis.svg" alt="logo_bis" />
        <h1 style={{marginLeft:10}}>Sythèse de vos recettes</h1>
      </Box>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {/* Première ligne */}
              <Grid item xs={2} sm={4} md={4} >
              <Item  sx={{width: '100%', height:"100%"}}>
                  
                  <h3>Distribution de l'impact carbone dans vos recettes</h3>
                  <RatiosChartsIndicators stats={stats["distributions"]["carbon"]} type="Impact carbone"/>
                </Item>
              </Grid>
  
              <Grid item xs={2} sm={4} md={4} >
                <Item sx={{height:"100%"}}>
                  <h3>Distribution de l'écoscore dans vos recettes</h3>
                  <RatiosChartsIndicators stats={stats["distributions"]["ecoscore"]} type="Ecoscore"/>
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4} >
                <Item sx={{height:"100%", width:"100%"}}>
                  <h3>Distribution du nutriscore dans vos recettes</h3>
                  <RatiosChartsIndicators stats={stats["distributions"]["nutriscore"]} type="Nutriscore"/>
                </Item>
              </Grid>
  
              {/* Deuxième ligne */}
              <Grid item xs={2} sm={4} md={4}>
                <Item sx={{width: '100%', height:"100%"}}>
                  <h3>Labels de vos produits</h3>
                  <RatiosCharts stats={stats["Label_part"]}/>
                  {/**Ajouter une version détaillé avec les labels précis */}
                </Item>
  
              </Grid>
              <Grid item xs={2} sm={4} md={4} sx={{maxHeight:375, }}>
                <Item sx={{height:"100%", paddingBottom:"10%"}}>
                  <h3>Provenances de vos produits</h3>
                  <RatiosCharts stats={stats["Provenance_part"]}/>
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4} >
              <Item sx={{height:"100%", width:"100%"}}>
                  <h3>Top 3 de vos recettes</h3>
                  <TopRecipes/>
                </Item>
  
              </Grid>
            </Grid>
      </div>
    );
  }
  
};

export default Dashboard;
