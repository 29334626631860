import { CosmosClient } from "@azure/cosmos";

// Fonction pour formater la date en français
export function formatDate(date){
    return date ? date.format("DD MMMM YYYY") : "";
};

export function fetchClientData(client_id){
    //Fetch recipes from cosmos db directly
  return new Promise((resolve, reject) => {
    let endpoint = "https://foodprint.documents.azure.com:443/";
    let key = "JndvuOyRC0jT7tm8sjYmOksQTQA4432l6sL7Krr6M2D9AayzLraukOA3cw6SoxKrR4NINl9p0nv2ACDbQbpegQ=="
    let databaseId = "plateforme_foodprint";
    let containerId = "Client";
    const client = new CosmosClient({ endpoint, key });
    const database = client.database(databaseId);
    const container = database.container(containerId);
    //Get  FROM DATABASE? ID = client
    container.item(client_id, client_id).read().then(({ resource: item }) => {
      resolve(item);
    });
  });
}

export function fetchRecipes(restaurant){
  //Fetch recipes from cosmos db directly
  return new Promise((resolve, reject) => {
    let endpoint = "https://foodprint.documents.azure.com:443/";
    let key = "JndvuOyRC0jT7tm8sjYmOksQTQA4432l6sL7Krr6M2D9AayzLraukOA3cw6SoxKrR4NINl9p0nv2ACDbQbpegQ=="
    let databaseId = "plateforme_foodprint";
    let containerId = restaurant;
    const client = new CosmosClient({ endpoint, key });
    const database = client.database(databaseId);
    const container = database.container(containerId);
    container.items.readAll().fetchAll().then(({ resources: items }) => {
      resolve(items);
    });
  });
}

export function fetchPresta(){
  //Fetch recipes from cosmos db directly
  return new Promise((resolve, reject) => {
    let endpoint = "https://foodprint.documents.azure.com:443/";
    let key = "JndvuOyRC0jT7tm8sjYmOksQTQA4432l6sL7Krr6M2D9AayzLraukOA3cw6SoxKrR4NINl9p0nv2ACDbQbpegQ=="
    let databaseId = "plateforme_foodprint";
    let containerId = "Prestataires";
    const client = new CosmosClient({ endpoint, key });
    const database = client.database(databaseId);
    const container = database.container(containerId);
    container.items.readAll().fetchAll().then(({ resources: items }) => {
      resolve(items);
    });
  });
}

export function fetchSubventions(){
  //Fetch recipes from cosmos db directly
  return new Promise((resolve, reject) => {
    let endpoint = "https://foodprint.documents.azure.com:443/";
    let key = "JndvuOyRC0jT7tm8sjYmOksQTQA4432l6sL7Krr6M2D9AayzLraukOA3cw6SoxKrR4NINl9p0nv2ACDbQbpegQ=="
    let databaseId = "plateforme_foodprint";
    let containerId = "Subventions";
    const client = new CosmosClient({ endpoint, key });
    const database = client.database(databaseId);
    const container = database.container(containerId);
    container.items.readAll().fetchAll().then(({ resources: items }) => {
      resolve(items);
    });
  });
}

export function fetchRessources(){
  //Fetch recipes from cosmos db directly
  return new Promise((resolve, reject) => {
    let endpoint = "https://foodprint.documents.azure.com:443/";
    let key = "JndvuOyRC0jT7tm8sjYmOksQTQA4432l6sL7Krr6M2D9AayzLraukOA3cw6SoxKrR4NINl9p0nv2ACDbQbpegQ=="
    let databaseId = "plateforme_foodprint";
    let containerId = "Ressources";
    const client = new CosmosClient({ endpoint, key });
    const database = client.database(databaseId);
    const container = database.container(containerId);
    container.items.readAll().fetchAll().then(({ resources: items }) => {
      resolve(items);
    });
  });
}
export function fetchAltRecipes(){
  //Fetch recipes from cosmos db directly
  return new Promise((resolve, reject) => {
    let endpoint = "https://foodprint.documents.azure.com:443/";
    let key = "JndvuOyRC0jT7tm8sjYmOksQTQA4432l6sL7Krr6M2D9AayzLraukOA3cw6SoxKrR4NINl9p0nv2ACDbQbpegQ=="
    let databaseId = "plateforme_foodprint";
    let containerId = "AltRecipes";
    const client = new CosmosClient({ endpoint, key });
    const database = client.database(databaseId);
    const container = database.container(containerId);
    //fetch recipes from cosmos db by id
    container.items.readAll().fetchAll().then(({ resources: items }) => {
      // random integer between 0 and 50
      /*let random1 = Math.floor(Math.random() * 50);
      let random2 = Math.floor(Math.random() * 50);
      let random3 = Math.floor(Math.random() * 50);*/
      resolve(items);

      //resolve([items[random1], items[random2], items[random3]]);
    });
  });
}

export function addrecipe(restaurant, data,accessToken){
  return new Promise((resolve, reject) => {
    data[restaurant] = restaurant;
    try{
      fetch('https://foodprint-api.azure-api.net/foodprintFunctionsV3/http_trigger_addRecipe?restaurant='+restaurant, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': "a9ab279af7544e16a0ee106909e1074f",
          'Authorization': `Bearer ${accessToken}`,  
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => {
          resolve(data);
        })
    }catch(error){
      console.error(error);
    }
  });
}

export function addRecipeFile(file, restaurant, accessToken) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      fetch('https://foodprint-api.azure-api.net/uploadRecipeFile/http_trigger_addRecipeFile?restaurant='+restaurant, {
        method: 'POST',
        headers: {
          'Ocp-Apim-Subscription-Key': 'a9ab279af7544e16a0ee106909e1074f',
          'Authorization': `Bearer ${accessToken}`,  
        },
        body: formData,
      })
        .then(response => response.json())
        .then(data => {
          resolve(data);
        });
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}

export function editrecipe(restaurant, data,accessToken){
  data[restaurant] = restaurant;

  return new Promise((resolve, reject) => {
    try{
      fetch('https://foodprint-api.azure-api.net/editRecipe/http_trigger_editRecipe?restaurant='+restaurant, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': "a9ab279af7544e16a0ee106909e1074f",
          'Authorization': `Bearer ${accessToken}`,  

        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => {
          resolve(data);
        })
    }catch(error){
      console.error(error);
    }
  });
}

export function generateReport(recipes_id, restaurant, accessToken){
  return new Promise((resolve, reject) => {
  fetch('https://foodprint-api.azure-api.net/foodprintFunctionsV3/http_trigger_generateExport?recipes_id='+recipes_id+'&restaurant='+restaurant, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': "a9ab279af7544e16a0ee106909e1074f",
      'Authorization': `Bearer ${accessToken}`,  

    }
  }) .then(response => response.json())
  .then(data => {
      // Créez un élément de lien pour le téléchargement
      const link = document.createElement('a');
      link.href = data.url_report;
      //link.href = "https://foodprint.blob.core.windows.net/foodprintrestaurant/AJIACO%20COLOMBIEN%2C%20CREME%20%26%20CAPRES%202024-03-25.xlsx?sp=r&st=2024-04-11T15:28:44Z&se=2024-04-11T23:28:44Z&spr=https&sv=2022-11-02&sr=b&sig=e%2BdewKGXgFo%2FuDO7mmwNeWCYkeLLVC%2BGNX6Z95ET7oA%3D";
      link.download = "report";  // Nom du fichier à télécharger
      link.click();
      // Nettoyez l'URL créée
      URL.revokeObjectURL(data.url_report);
      resolve();
  })
});
}

export function generateProductionReport(restaurant, date, accessToken){
  return new Promise((resolve, reject) => {
  fetch("https://foodprint-api.azure-api.net/foodprintFunctionsV3/http_trigger_productionReport?restaurant="+restaurant+"&date="+date, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': "a9ab279af7544e16a0ee106909e1074f",
      'Authorization': `Bearer ${accessToken}`,  
    }
  }) .then(response => response.json())
  .then(data => {
      // Créez un élément de lien pour le téléchargement
      const link = document.createElement('a');
      link.href = data.url_report;
      //link.href = "https://foodprint.blob.core.windows.net/foodprintrestaurant/AJIACO%20COLOMBIEN%2C%20CREME%20%26%20CAPRES%202024-03-25.xlsx?sp=r&st=2024-04-11T15:28:44Z&se=2024-04-11T23:28:44Z&spr=https&sv=2022-11-02&sr=b&sig=e%2BdewKGXgFo%2FuDO7mmwNeWCYkeLLVC%2BGNX6Z95ET7oA%3D";
      link.download = "report";  // Nom du fichier à télécharger
      link.click();
      // Nettoyez l'URL créée
      URL.revokeObjectURL(data.url_report);
      resolve();
  })
});
}

export function create_new_user(restaurant, gestionnaire, accessToken){
  return new Promise((resolve, reject) => {
  fetch('https://foodprint-api.azure-api.net/add-new-user/http_trigger_newuser?restaurant='+restaurant+'&gestionnaire='+gestionnaire, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': "a9ab279af7544e16a0ee106909e1074f",
      'Authorization': `Bearer ${accessToken}`,  
    }
  }).then(response =>response.json())
  .then(data => {
      resolve(data);
  }).catch(error => {
    resolve(error);
  });
});
}

export function nettoyerChaine(chaine) {
  // Remplace les caractères accentués par leurs équivalents sans accent
  const sansAccent = chaine.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  // Supprime les caractères spéciaux et les espaces
  let resultat = sansAccent.replace(/[^a-zA-Z0-9]/g, '');
  // Met en minuscule
  resultat = resultat.toLowerCase();
  return resultat;
}