import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import test_illustration from './test_illustration.jpg';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const decodeText = (text) => {
  try {
    return decodeURIComponent(escape(text));
  } catch (e) {
    return text; // Si l'encodage échoue, retourner le texte original
  }
};

const decodeRecipe = (recipe) => {
  return {
    ...recipe,
    Name: decodeText(recipe.Name),
    Ingredients: recipe.Ingredients.map(ingredient => ({
      ...ingredient,
      Name: decodeText(ingredient.Name)
    })),
    Steps: recipe.Steps.map(step => decodeText(step))
  };
};

export default function RecipeReviewCard({ recipe }) {
  const decodedRecipe = decodeRecipe(recipe);

  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [portions, setPortions] = React.useState(parseInt(decodedRecipe.Nb_portion)); // État pour le nombre de portions

  const getScoreColor = (score) => {
    if (score >= 80) {
      return 'green';
    } else if (score >= 50) {
      return 'orange';
    } else {
      return 'red';
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePortionsChange = (event) => {
    setPortions(event.target.value);
  };
  var scoreColor = getScoreColor(parseInt(decodeRecipe.ecoscore100));

  return (
    <div>
      <Card sx={{ maxWidth: 345, borderRadius: 5 }}>
        <CardHeader title={decodedRecipe.Name} />
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
              <img
                src={`./ecoscore/${decodedRecipe.ecoscore}.svg`}
                alt={`${decodedRecipe.ecoscore}`}
                height="12%"
                width="12%"
              />
              <img
                src={`./nutriscore/${decodedRecipe.nutriscore}.svg`}
                alt={`${decodedRecipe.nutriscore}`}
                height="30%"
                width="30%"
              />
            </div>
          <Typography variant="body2" color="text.secondary">
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{ textAlign: 'left' }}>
            <p><strong>Score ecologique : <span style={{ color: scoreColor }}>{decodedRecipe.ecoscore100}/100</span></strong></p>
            <p><strong>Empreinte carbone : {decodedRecipe.footprint}gCO2eq/portion</strong></p>
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Ingredients:
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{ textAlign: 'left' }}>
            <ul>
              {decodedRecipe.Ingredients.map((ingredient, index) => (
                <li key={index}>
                  {ingredient.Name}: {ingredient.Quantity} {ingredient.Unit}
                </li>
              ))}
            </ul>
          </Typography>
        </CardContent>
        <CardActions disableSpacing sx={{ justifyContent: 'center' }}>
          <Button size="small" onClick={handleClickOpen}>Voir les details de la recette</Button>
        </CardActions>
      </Card>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          {decodedRecipe.Name}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            label="Nombre de portions"
            type="number"
            value={portions}
            onChange={handlePortionsChange}
            fullWidth
            margin="normal"
            InputProps={{ inputProps: { min: 1 } }} // Limiter les portions à un minimum de 1
          />
          <Typography variant="h6">Pour : {portions} portions</Typography>
          <Typography variant="h6">Ingredients</Typography>
          <Typography variant="body2" style={{ textAlign: 'left' }}>
            <ul>
              {decodedRecipe.Ingredients.map((ingredient, index) => (
                <li key={index}>
                  {ingredient.Name}: {(ingredient.Quantity / decodedRecipe.Nb_portion * portions).toFixed(2)} {ingredient.Unit}
                </li>
              ))}
            </ul>
          </Typography>
          <Typography variant="h6">Etapes</Typography>
          <Typography variant="body2" style={{ textAlign: 'left' }}>
            <ol>
              {decodedRecipe.Steps.map((step, index) => (
                <p key={index}>{step}</p>
              ))}
            </ol>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
