import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchPresta, fetchRecipes, fetchRessources, fetchAltRecipes, fetchSubventions, fetchClientData, nettoyerChaine } from './utils/utils'; // Assurez-vous que le chemin est correct
import { useMsal } from "@azure/msal-react";
import { jwtDecode } from "jwt-decode";
import { Navigate } from 'react-router-dom';

const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [restaurant, setRestaurant] = useState("");
  const [listRestaurants, setListRestaurants] = useState([]); // Add the list of restaurants here
  const [client, setClient] = useState("");
  const { instance, accounts } = useMsal();
  const [prod_stats, setProd_stats] = useState({});
  const [reports, setReports] = useState([]);
  const [presta, setPresta] = useState([]);
  const [ressources, setRessources] = useState([]);
  const [altRecipes, setAltRecipes] = useState([]);
  const [subventions, setSubventions] = useState([]);
  const [fonctionnalites, setFonctionnalites] = useState([]);
  const [accessToken, setAccessToken] = useState(null);
  const [newUser, setnewUser] = useState(false);
  const [gestionnaire, setGestionnaire] = useState(null);
  const push = (item) => {
    setData([...data, item]); // Ajoutez le nouvel élément à la liste de données
  };

  useEffect(() => {
    setIsLoading(true);
    if (accounts.length > 0) {
      const request = {
        scopes: ["https://foodprintfrance.onmicrosoft.com/api/read"],
        account: accounts[0]
      };

      instance.acquireTokenSilent(request)
        .then(response => {
          setAccessToken(response.idToken);
          const decoded = jwtDecode(response.idToken);
          
          let typeClient = "";
          if(decoded.extension_Type){
            typeClient = decoded.extension_Type;
          }else{
            typeClient = "Collectif";
          }
          
          //Handle old version of client and restaurant data
          let res = null
          let clt  = null
          let g = null
          if(decoded.extension_Restaurants !== undefined){
            res = decoded.extension_Restaurants.split(",")
             clt = decoded.extension_Client      
          }
          else{
            //Handle new version of client and restaurant data
            //See documentation for more details (notion)
            res = [nettoyerChaine(decoded.jobTitle)]
            clt  = nettoyerChaine(decoded.jobTitle)
            g = nettoyerChaine(decoded.name)
          }
          setRestaurant(res[0])
          setClient(clt)
          setGestionnaire(g)
          setListRestaurants(res)

          //Handle new user
          fetchClientData(clt).then((c) => {
            if(c === undefined){
              setnewUser(true);
            }else{

              loadDataForRestaurant(res[0], clt);
              setnewUser(false);
            }
          })
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [accounts, instance]);

  const loadDataForRestaurant = (restaurant, clt) => {
    //TODO : get current month prod stats file from the same azf (add option)
    //get month and year
    let date = new Date();
    let month = date.getMonth() + 1;
    //Month with 2 digits
    month = month < 10 ? '0' + month : month;
    let year = date.getFullYear();
    setRestaurant(restaurant);
    setProd_stats({});
    fetchClientData(clt).then((c) => {
      setFonctionnalites(c.fct);
      fetchSubventions().then((s) => {
        setSubventions(s);
      fetchAltRecipes().then((altRecipes) => {
        setAltRecipes(altRecipes);
        fetchRessources().then((ressources) => {
          setRessources(ressources);
          fetchPresta().then((presta) => {
            setPresta(presta);
            fetchRecipes(restaurant)
            .then((recipesData) => {
              // Name recipe lower case, except first char
              let a = []
              recipesData.forEach((recipe) => {
                // if recipe.id contains "prod"
                if(recipe.id.includes("prod")){
                  let stats_name = "prod-"+year+"-"+month;
                  a.push(recipe.id);
                  if(recipe.id === stats_name){
                    // add recipe.id in  list of reports
                    setProd_stats(recipe);
                  }
                  //remove current recipe from list
                  recipesData = recipesData.filter((item) => item.id !== recipe.id);
                }
                else{
                  recipe.Name = recipe.Name.charAt(0).toUpperCase() + recipe.Name.slice(1).toLowerCase();
                }

              });
              setReports(a);
              setData(recipesData);
              setIsLoading(false);
            })
            .catch((error) => {
              setError(error);
              setIsLoading(false);
            });
          });
        });
      });
      });
    });
  }
  return (
    <DataContext.Provider value={{ data, prod_stats, reports, restaurant, listRestaurants, client, isLoading, error, loadDataForRestaurant, presta, ressources, altRecipes, subventions, fonctionnalites, accessToken,newUser, setnewUser, gestionnaire }}>
      {children}
    </DataContext.Provider>
  );
};
