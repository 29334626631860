import React from 'react';
import "@fontsource/alegreya-sans"; // Defaults to weight 400
import { Box, Container, Unstable_Grid2 as Grid } from '@mui/material';
import ProgressBarWithMagnifier from './ProgressBarWithMagnifier/ProgressBarWithMagnifier';
import './dashboard.css';
import RatiosCharts from './RatiosCharts';
import ReportTable from './reportTable';
import TopRecipes from './TopRecipes';
import RatiosChartsIndicators from './RatiosChartsIndicators';
import LineChartMonths from './LineChartMonths';
import styled from '@mui/material/styles/styled';
import Paper from '@mui/material/Paper';
import { useData } from '../../DataProvider';
import  RestaurantTable  from './RestaurantTable';
import CircularProgress from '@mui/material/CircularProgress';
import { Alert, AlertTitle } from '@mui/material';

function prepareStats(stats){
  //TODO : cas où les stats sont vides
  if(!stats["Label_part"]){
    return false;
  }
  // siqo = sum of all except bio
  stats["siqo"] = 0;
  //If no bio, set it to 0
  if(!stats["Label_part"]["bio"]){
    stats["Label_part"]["bio"] = 0;
  }
  for (let key in stats["Label_part"]) {

    if(key !== "bio"){
      stats["siqo"] += parseInt(stats["Label_part"][key]*100);
    }
  }
  stats["bio"] = parseInt(stats["Label_part"]["bio"]*100);
  stats["conventionel"] = parseInt(100 - stats["bio"] - stats["siqo"]);

  return stats;
}

const Dashboard = () => {
  //Get current month in french
  const date = new Date();
  const options = { month: 'long' };
  let month = new Intl.DateTimeFormat('fr-FR', options).format(date);
  //Upper case first char
  month = month.charAt(0).toUpperCase() + month.slice(1);
  //Get current year
  const year = date.getFullYear();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  //Data provider
  const { data, prod_stats, reports, restaurant, listRestaurants, client, isLoading, error } = useData();
  if(isLoading){
    return(
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center' }} >
          <h1>Chargement des données</h1>
          <CircularProgress />
        </Box>
      </div>
    )
  }
  else if(error){
    return (
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center', verticalAlign: 'center' }} >
        <Alert severity="error" sx={{verticalAlign:'center'}}>
          <AlertTitle>Erreur</AlertTitle>
            Une erreur est survenue au chargement des données de votre restaurant. <br/>
            Rafraichissez la page, si cela ne fonctionne toujours pas, contactez le support (rubrique Aide & Contact).
        </Alert>
        </Box>
      </div>
    )
  }
  else if(Object.keys(prod_stats).length === 0){
    return (
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center', verticalAlign: 'center' }} >
        <Alert severity="info" sx={{verticalAlign:'center'}}>
          <AlertTitle>Information</AlertTitle>
            Vous n'avez pas encore de données à afficher pour ce mois ci. <br/>
            Veuillez saisir vos menus.
        </Alert>
        </Box>
      </div>
    )
  }
  // else if restaurant inclde admin
  else if (restaurant.includes("admin")) {
    let stats = prepareStats(prod_stats);
    if(stats == false){
      //TODO :Afficher tout de même les rapports ?
      return(
        <div className="dashboard">
          <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }} >
            <img src="logo_bis.svg" alt="logo_bis" />
            <h1 style={{marginLeft:10}}>Tableau de bord administrateur / {month} {year}</h1>
          </Box>
          <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center' }} >
            <h2>Vous n'avez pas encore de données à afficher pour ce mois ci</h2>
          </Box>
        </div>
      )
    }
    return (
      <div className="dashboard">
      <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }} >
        <img src="logo_bis.svg" alt="logo_bis" />
        <h1 style={{marginLeft:10}}>Tableau de bord administrateur / {month} {year}</h1>
      </Box>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {/* Première ligne */}
              <Grid item xs={4} sm={8} md={8} >
                <Item sx={{height:"100%", width:"100%"}}>
                  <h3>Synthèse des restaurants</h3>
                    <RestaurantTable/>
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4} >
              <Item sx={{height:"100%"}}>
                  <h3>Les ratios EGalim</h3>
                  <RatiosCharts stats={stats}/>
                </Item>
              </Grid>
              {/* Deuxième ligne */}
              <Grid item xs={2} sm={4} md={4}>
                <Item sx={{width: '100%'}}>
                  <h3>Impact carbone moyen de vos recettes</h3>
                  <h4 className="carbonImpact">1.2kg de CO2eq/portion en moyenne</h4>
                  <ProgressBarWithMagnifier value={1200} />
                  <h4 className='carbonGoal'>Mon objectif : 0.7kg de CO2eq/portion en moyenne</h4>
                </Item>
  
              </Grid>
              <Grid item xs={2} sm={4} md={4} sx={{maxHeight:375, }}>
                <Item sx={{height:"100%", maxHeight:"80%", paddingBottom:"10%"}}>
                  <h3>Evolution de l'impact carbone moyen</h3>
                  <LineChartMonths stats={stats}/>
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4} >
                <Item sx={{height:"100%", width:"100%"}}>
                  <h3>Repartition des indicateurs</h3>
                  <RatiosChartsIndicators stats={stats}/>
                </Item>
              </Grid>
            </Grid>
      </div>
    );
  }
  else if(prod_stats["Label_part"]){
    let stats = prepareStats(prod_stats);
    return (
      <div className="dashboard">
      <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }} >
        <img src="logo_bis.svg" alt="logo_bis" />
        <h1 style={{marginLeft:10}}>Tableau de bord / {month} {year}</h1>
      </Box>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {/* Première ligne */}
              <Grid item xs={2} sm={4} md={4} >
                <Item sx={{height:"100%", width:"100%"}}>
                  <h3>Rapports de production</h3>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <ReportTable reports_date={reports} restaurant={restaurant}/>
                  </Box>
                </Item>
              </Grid>
  
              <Grid item xs={2} sm={4} md={4} >
                <Item sx={{height:"100%"}}>
                  <h3>Les ratios de vos recettes</h3>
                  <RatiosCharts stats={stats}/>
                  
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4} >
                <Item sx={{height:"100%", width:"100%"}}>
                  <h3>Repartition des indicateurs de vos recettes</h3>
                  <RatiosChartsIndicators stats={stats}/>
                </Item>
              </Grid>
  
              {/* Deuxième ligne */}
              <Grid item xs={2} sm={4} md={4}>
                <Item sx={{width: '100%'}}>
                  <h3>Impact carbone moyen de vos recettes</h3>
                  <h4 className="carbonImpact">{(stats["Average_footprint"]/1000).toFixed(2)}kg de CO2eq/portion en moyenne</h4>
                  <ProgressBarWithMagnifier value={stats["Average_footprint"]}  />
                  <h4 className='carbonGoal'>Mon objectif : 0.6kg de CO2eq/portion en moyenne</h4>
                </Item>
  
              </Grid>
              <Grid item xs={2} sm={4} md={4} sx={{maxHeight:375, }}>
                <Item sx={{height:"100%", paddingBottom:"10%"}}>
                  <h3>Evolution de l'impact carbone moyen</h3>
                  <LineChartMonths stats={stats}/>
                </Item>
              </Grid>
              <Grid item xs={2} sm={4} md={4} >
                <Item>
                  <h3>Top des recettes</h3>
                  <TopRecipes/>
                </Item>
  
              </Grid>
            </Grid>
      </div>
    );
  }
  
};

export default Dashboard;
