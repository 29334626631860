import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { generateProductionReport } from '../../utils/utils';
import CircularProgress from '@mui/material/CircularProgress';
import { useData } from '../../DataProvider';
const columns = [
  { id: 'date', label: 'Date', minWidth: 100 },
  {
    id: 'action',
    label: 'Action',
    minWidth: 100,
    align: 'left',
  },
];

function createData(date, action) {
  return { date, action};
}

let rows = [];
export default function ReportTable({reports_date, restaurant}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [loadingReport, setLoadingReport] = React.useState([null, false]);
  const { accessToken } = useData();
  rows = [];

  //sort reverse reports_date
  reports_date.sort().reverse()
  for (let i = 0; i < reports_date.length; i++) {
    //reports_date[i] : prod-2024-03, transformer en date "Mars 2024"
    let mois = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    let year = reports_date[i].split("-")[1];
    let month = parseInt(reports_date[i].split("-")[2]);
    month = mois[parseInt(month)-1];
    rows.push(createData(month+" "+year, reports_date[i]));
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const downloadReport = (restaurant, date) => {
    setLoadingReport([date, true]);
    generateProductionReport(restaurant, date, accessToken).then(() => {
      setLoadingReport([date, false]);
    });
  }

  return (
    <Paper sx={{ width: '100%', height:"65%" }}>
      <TableContainer sx={{}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell align={columns[0].align} style={{}}>
                            {row[columns[0].id]}
                    </TableCell>
                    <TableCell align={columns[1].align} style={{}}>
                        <Button disabled={loadingReport[1]} variant="contained" style={{ backgroundColor: '#38A48E', color: '#FFFFFF', borderRadius:10 }} onClick={() => downloadReport(restaurant, row[columns[1].id])}>
                          Télécharger
                          {loadingReport[1] && row[columns[1].id] == loadingReport[0] &&
                            (
                              <>
                                <CircularProgress />
                              </>

                            )}
                        </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[3]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}