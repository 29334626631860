import React, { useState } from 'react';
import './generatenewrecipe.css'; // Votre CSS pour le tableau de bord
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useData } from '../../DataProvider';
import RecipeCard from './RecipeCard';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import OpenAIApi from "openai";
import Autocomplete from '@mui/material/Autocomplete';
import beta from './beta.svg';
import { useNavigate } from 'react-router-dom';
const alt1 = {
  // ... alt1 data
};

const alt2 = {
  // ... alt2 data
};

const alt3 = {
  // ... alt3 data
};

const GenerateNewRecipe = () => {
  const [formData, setFormData] = useState({
    typeDePlat: '',
    ingredients: [],
    regime: '',
    origin: '',
  });
  let navigate = useNavigate();
  const [visibility, setVisibility] = useState('hidden');
  const [recipes, setRecipes] = useState([alt1, alt2, alt3]);
  const [loadingGpt, setLoadingGpt] = useState(false);
  const [query, setQuery] = useState('');
  const [ingr, setIngr] = useState([]);
  const [hideResults, setHideResults] = useState(true);
  const [errorGeneration, setErrorGeneration] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const goToRessources = () => {
    navigate("/ressources/")

  };
  const handleIngredientsChange = (event, newValue) => {
    setFormData({
      ...formData,
      ingredients: newValue,
    });
  };

  const handleQueryChange = async (ingredient_name_query) => {
    setQuery({"Nom_du_Produit_en_Français": ingredient_name_query});
    const base = "https://data.ademe.fr/data-fair/api/v1/datasets/agribalyse-31-synthese/lines?";
    const select= "select=Nom_du_Produit_en_Français,Code_AGB,Groupe_d'aliment,Code_CIQUAL,DQR,Sous-groupe_d'aliment";
    const column_filter = "&q_mode=\"complete\"&q=Nom_du_Produit_en_Français:\"" + ingredient_name_query+  "\" |";
    const query = base + select + column_filter; 

    fetch(query)
      .then(response => response.json())
      .then(res => {
        const ingredients = [];
        setIngr([]);
        for (let i = 0; i < res.results.length; i++) {
          let group = res.results[i]["Groupe_d'aliment"];
          if(group != "entrees et plats composes" && group !="entrées et plats composés"){
            ingredients.push(res.results[i]);
          }
        }
        setIngr(ingredients);
        setHideResults(false);
      })
      .catch(error => console.error(error));
  };

  const handleSubmit = (e) => {
    //useCreditGeneration(restaurant)
    setLoadingGpt(true);
    e.preventDefault();
    let prompt = `Je veux que tu me donnes trois recettes.
                  On veut faire 1 portion de chaque recette
                  Une portion pèse environ 300 grammes une fois cuit, fais attention aux quantités pour que cela reste cohérent

                  On doit avoir :
                  - 1/4 de légumineuse
                  - 1/4 de céréales
                  - 1/2 de légumes
                  et eventuellement une pièce de viande ou poisson
                  Ce sont des proportions approximatives, il faut juste rester dans ces ordres de grandeurs
                  L’apport de protéines doit être de 9.5 grammes par portion

                  Mes recettes sont dans le format suivant :
                  [{
                    "name": "Cannellonis",
                    "ecoscore": "C",
                    "nutriscore": "B",
                    "footprint": "950",
                    "ecoscore100": "72",
                    "ingredients": [
                      "Pates à cannellonis",
                      "Ricotta",
                      "Epinards",
                      "Parmesan rape",
                      "Oignons",
                      "Gousses d'ail",
                      "Tomates concassees",
                      "Sauce tomate",
                      "Mozzarella",
                      "Huile d'olive",
                      "Sel",
                      "Poivre",
                      "Noix de muscade"
                    ],
                    "etapes": [
                      "Prechauffer le four à 180°C.",
                      "Faire revenir les oignons et l'ail eminces dans l'huile d'olive.",
                      "Ajouter les epinards et cuire jusqu'à ce qu'ils soient fondants.",
                      "Melanger les epinards cuits avec la ricotta, le parmesan rape, le sel, le poivre et la noix de muscade.",
                      "Farcir les pates à cannellonis avec ce melange.",
                      "Dans un plat à gratin, etaler une couche de sauce tomate, puis disposer les cannellonis farcis par-dessus.",
                      "Recouvrir de sauce tomate et parsemer de mozzarella rapee.",
                      "Cuire au four pendant environ 30 minutes, jusqu'à ce que le dessus soit dore et bouillonnant."
                    ],
                    "quantites": {
                      "portions": 30,
                      "details": {
                        "Pates à cannellonis": 1.875,
                        "Ricotta": 3,
                        "Epinards": 3.75,
                        "Parmesan rape": 750,
                        "Oignons": 15,
                        "Gousses d'ail": 24,
                        "Tomates concassees": 3,
                        "Sauce tomate": 3.75,
                        "Mozzarella": 1.5,
                        "Huile d'olive": 0.5,
                        "Sel": 0.0,
                        "Poivre": 0.05,
                        "Noix de muscade": 0.05
                      },
                      "unit": {
                        "Pates à cannellonis": "kg",
                        "Ricotta": "kg",
                        "Epinards": "kg",
                        "Parmesan rape": "kg",
                        "Oignons": "kg",
                        "Gousses d'ail": "kg",
                        "Tomates concassees": "kg",
                        "Sauce tomate": "l",
                        "Mozzarella": "kg",
                        "Huile d'olive": "l",
                        "Sel": "kg",
                        "Poivre": "kg",
                        "Noix de muscade": "kg"
                      }
                    }
                  }]
                    Respecte rigoureusement le format donné.
                  Il faut que l'objet json retourné contienne une liste avec les 3 recettes. 

                  Je veux que tu rajoutes aussi les étapes
                  Je ne veux pas de desserts
                  Je veux en sortie une liste json de chaque objet recette
                  Les quantités doivent être en kg ou l. Je ne veux pas de mesure approximative. 

                  \n`
    //Le nom de l'ingrédient est dans la liste ingredients[i]["Nom_du_Produit_en_Français"]
    if(formData.ingredients.length > 0){
      let ing = formData.ingredients.map(ingredient => ingredient["Nom_du_Produit_en_Français"].split(",")[0])
      prompt += `Les recettes doivent contenir les ingrédients suivants : ${ing.join(", ")} \n`
     
    }
    if(formData.typeDePlat !== ''){
            prompt +=  `Les recettes doivent être un plat type ${formData.typeDePlat}\n`
    }
    if(formData.origin !== ''){
        prompt +=  `Les recettes doivent être d'origine ${formData.origin}\n`
      }    
    if(formData.regime !== '') {      
        prompt +=  `Les recettes doivent être un plat ${formData.regime}\n`
      }
    
    const openai = new OpenAIApi ({
      apiKey: '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',
      dangerouslyAllowBrowser:true
    });
    openai.chat.completions.create({
      messages: [
        {"role": "system", "content": "Tu es un chef assistant pour donner 3 recettes à partir de paramètres données. Tu dois les renvoyer sous la forme d'objet json'"},
        {"role": "user", "content": prompt}
      ],
      model: "gpt-3.5-turbo",
      response_format:{ "type": "json_object" },
    }).then((completion) => {
      let res = JSON.parse(completion.choices[0].message.content)
      //check if the response is valid with all the required fields
      //Check recettes
      if(!res.recettes || !res.recettes[0].name || !res.recettes[0].ingredients || !res.recettes[0].etapes|| !res.recettes[0].quantites){
        setErrorGeneration(true)
        setLoadingGpt(false);
      }else{
        setRecipes([res.recettes[0], res.recettes[1], res.recettes[2]])
        setVisibility('visible');
        setLoadingGpt(false);
      }

    });
  };

  const { data, prod_stats, reports, restaurant, listRestaurants, client, isLoading, error } = useData();
  if(isLoading){
    return(
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center' }} >
          <h1>Chargement des données</h1>
          <CircularProgress />
        </Box>
      </div>
    )
  }

  return (
    <div className="myrecipes">
      <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
        <img src="logo_bis.svg" alt="logo_bis" />
        <h1 style={{marginLeft:10, marginRight:10}}>Chef assistant</h1>
        <img src={beta} alt="beta" width={100}/>
      </Box>

    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: 2 }}>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="typeDePlat-label">Type de plat</InputLabel>
          <Select
            labelId="typeDePlat-label"
            name="typeDePlat"
            value={formData.typeDePlat}
            onChange={handleChange}
            label="Type de plat"
          >
            <MenuItem value="">
              <em>Sélectionnez un type</em>
            </MenuItem>
            <MenuItem value="gratin">Gratin</MenuItem>
            <MenuItem value="platEnSauce">Plat en sauce</MenuItem>
            <MenuItem value="platCompose">Plat composé</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="origin-label">Origine du plat</InputLabel>
          <Select
            labelId="origin-label"
            name="origin"
            value={formData.origin}
            onChange={handleChange}
            label="Origine du plat"
          >
            <MenuItem value="">
              <em>Sélectionnez un type de gastronomie</em>
            </MenuItem>
            <MenuItem value="Italien">Italien</MenuItem>
            <MenuItem value="français">Français</MenuItem>
            <MenuItem value="Européen">Européen</MenuItem>
            <MenuItem value="Asiatique">Asiatique</MenuItem>
            <MenuItem value="Sud américain">Sud Américain</MenuItem>
            <MenuItem value="Nord américain">Sud Américain</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ flex: 2 }}>
          <Autocomplete
              multiple
              options={ingr}
              getOptionLabel={(option) => option['Nom_du_Produit_en_Français']}
              onChange={handleIngredientsChange}
              onInputChange={(event, newInputValue) => {handleQueryChange(newInputValue);}}
              isOptionEqualToValue={(option, value) => option['Nom_du_Produit_en_Français'] === value['Nom_du_Produit_en_Français']}
              sx={{ minWidth: 250 }}
              renderInput={(params) => <TextField {...params} label="Ingrédients" />}
            />
        </FormControl>

        <FormControl sx={{ flex: 1 }}>
          <InputLabel id="regime-label">Régime</InputLabel>
          <Select
            labelId="regime-label"
            name="regime"
            value={formData.regime}
            onChange={handleChange}
            label="Régime"
          >
            <MenuItem value="">
              <em>Sélectionnez un type</em>
            </MenuItem>
            <MenuItem value="vegan">Vegan</MenuItem>
            <MenuItem value="vegetarien">Végétarien</MenuItem>
            <MenuItem value="avec de la viande">Omnivore</MenuItem>
          </Select>
        </FormControl>
      </Box>

    <Box sx={{ display: 'flex', flexDirection: 'line', alignItems: 'center', gap: 2 }}>
      <Button type="submit" variant="contained" color="primary" sx={{ maxWidth: '400px' }} disabled={loadingGpt} >
        Créer des recettes à partir des éléments
        {loadingGpt && (
          <>
            <CircularProgress />
          </>
        )}
      </Button>

    </Box>

    </Box>

      <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center', gap:5, marginTop:7, visibility:visibility}} >
        <RecipeCard altRecipe={recipes[0]}/>
        <RecipeCard altRecipe={recipes[1]}/>
        <RecipeCard altRecipe={recipes[2]}/>
      </Box>
      {
        loadingGpt && <h2>Votre assistant réfléchit, laissez lui quelques secondes...</h2>
      }
      {
        errorGeneration && <h2>Erreur lors de la génération des recettes. Veuillez réessayer</h2>
      }
            <Button variant="contained" color="success" onClick={goToRessources} sx={{ maxWidth: '400px', marginTop:10 }}>
          Voir plus de recettes durables
      </Button>


    </div>
  );
};

export default GenerateNewRecipe;
