import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NutritionalValuesName from './NutritionalValuesName.json'

function NutritionalValues(props) {
  // Assurez-vous que la propriété recipe est correctement déstructurée si nécessaire
  // Par exemple, si recipe est un objet directement, vous pourriez devoir ajuster l'accès aux propriétés
  const { recipe } = props;
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="nutrition table">
        <TableHead>
          <TableRow>
            {/* Génération des en-têtes de colonnes pour chaque valeur nutritionnelle */}
            {NutritionalValuesName.map((name) => (
              <TableCell key={name}>{name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {/* Génération des cellules pour chaque valeur, en utilisant recipe[nom_de_la_valeur] pour accéder à la valeur correspondante */}
            {NutritionalValuesName.map((name) => (
              <TableCell key={name} align="right">
                {recipe[name].toFixed(3)}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default NutritionalValues;
