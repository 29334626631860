import React, { useState } from 'react';
import { TextField, Button, MenuItem, Box, Typography, Paper, Snackbar, Alert } from '@mui/material';

const categories = [
  { id: 'support-de-communication', label: 'Support de communication' },
  { id: 'reglementation', label: 'Réglementation' },
  { id: 'guide-transition', label: 'Guide de la transition écologique' },
  { id: 'rh', label: 'Ressources humaines' },
  { id: 'creation-menu', label: 'Création de menus' },
  { id: 'stock-approvisionnement', label: 'Gestion des stocks et approvisionnement' },
  { id: 'managment', label: 'Management' },
  { id: 'fiches-recettes', label: 'Fiches recettes' },
  { id: 'hygiene-securite', label: 'Hygiène & Sécurité' },
  { id: 'equipement', label: 'Equipement' },
  { id: 'recettes-entree', label: 'Recettes entrée' },
  { id: 'recettes-plat', label: 'Recettes plat' },
  { id: 'recettes-dessert', label: 'Recettes dessert' },

];

const FormPage = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState('');
  const [resourceFile, setResourceFile] = useState(null);
  const [illustrationFile, setIllustrationFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Bloquer le bouton en activant l'état de chargement

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('category', category);

    const tagsArray = tags ? tags.split(',').map(tag => tag.trim()) : [];
    tagsArray.forEach((tag, index) => {
        formData.append(`tags[${index}]`, tag);
    });

    formData.append('resourceFile', resourceFile);
    formData.append('illustrationFile', illustrationFile);

    try {
      const response = await fetch('https://foodprint-api.azure-api.net/addRessources/http_trigger_addRessource', {
        method: 'POST',
        body: formData,
        headers: {
            'Ocp-Apim-Subscription-Key': 'a9ab279af7544e16a0ee106909e1074f'
        },
      });
      
      if (response.status === 200) {
        setSnackbar({ open: true, message: 'Ressource bien ajoutée', severity: 'success' });
      } else {
        setSnackbar({ open: true, message: 'Erreur lors de l\'ajout de la ressource', severity: 'error' });
      }
    } catch (error) {
      console.error('Error submitting form', error);
      setSnackbar({ open: true, message: 'Erreur lors de l\'ajout de la ressource', severity: 'error' });
    } finally {
      setLoading(false); // Réactiver le bouton après la requête
    }
  };

  return (
    <Box
      sx={{
        marginLeft: '17%',
        padding: 4,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Paper elevation={3} sx={{ padding: 4, width: '100%', maxWidth: 600 }}>
        <Typography variant="h4" gutterBottom align="center">
          Ajouter une Ressource
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <TextField
            label="Titre"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            fullWidth
          />

          <TextField
            label="Description"
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            multiline
            rows={4}
            required
            fullWidth
          />

          <TextField
            select
            label="Catégorie"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            variant="outlined"
            required
            fullWidth
          >
            {categories.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Tags (séparés par des virgules)"
            variant="outlined"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            fullWidth
          />

          <Button
            variant="contained"
            component="label"
            fullWidth
            disabled={loading} // Désactiver le bouton si chargement en cours
          >
            {resourceFile ? resourceFile.name : "Télécharger le fichier PDF (Ressource)"}
            <input
              type="file"
              hidden
              accept="application/pdf"
              onChange={(e) => handleFileChange(e, setResourceFile)}
              required
            />
          </Button>

          <Button
            variant="contained"
            component="label"
            fullWidth
            disabled={loading} // Désactiver le bouton si chargement en cours
          >
            {illustrationFile ? illustrationFile.name : "Télécharger l'illustration PNG"}
            <input
              type="file"
              hidden
              accept="image/png"
              onChange={(e) => handleFileChange(e, setIllustrationFile)}
              required
            />
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={loading} // Désactiver le bouton si chargement en cours
          >
            {loading ? 'Envoi en cours...' : 'Envoyer'}
          </Button>
        </Box>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Centrer en haut
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ 
            width: '100%', 
            fontSize: '1.25rem' // Augmenter la taille de la police
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FormPage;
