import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, quantity,unit, labels, origin, cooking, packaging, storage) {
  return { name, quantity,unit, labels, origin, cooking, packaging, storage };
}

export default function IngredientsList(ingredients) {
  const rows = ingredients.ingredients.map((ingredient) => {
    //Concatenate all label into one string
    //Remove [ and ] from string ingredient.labels

    //Si ingredients.labels est une string, on la transforme en array. Elle se présetenra sous la forme : "[label1, label2, label3]", ou simplement "[]"
    let labels_array = []
    if (typeof(ingredient.Labels) === "string") {
      //On enlève les crochets
      let labels_string = ingredient.Labels.slice(1, -1)
      
      //On sépare les labels
      labels_array = labels_string.split(", ")
      //Pour chaque labels on supprime ' 
      labels_array = labels_array.map(label => {
        return label.slice(1, -1)
      })
    } else {
      labels_array = ingredient.Labels
    }
    //Si c'est déjà une liste, on la laisse telle quelle
    if (!labels_array) {
      labels_array = []
    }

    let labels = ""
    labels_array.forEach(label => {
      labels += label.toUpperCase() + ", "
    });
    labels = labels.slice(0, -2)
    //Si le premier caractère est une virgule, on le supprime
    if (labels.charAt(0) === ",") {
      labels = labels.slice(1)
    }
    return createData(ingredient.Name, ingredient.Quantity, ingredient.Unit, labels, ingredient.Origin.toUpperCase(), ingredient.Cooking, ingredient.Packaging, ingredient.Storage)
  })
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Ingrédient</TableCell>
            <TableCell align="right">Quantité</TableCell>
            <TableCell align="right">Unité</TableCell>
            <TableCell align="right">Labels</TableCell>
            <TableCell align="right">Provenance</TableCell>
            <TableCell align="right">Cuisson</TableCell>
            <TableCell align="right">Emballage</TableCell>
            <TableCell align="right">Conservation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.quantity}</TableCell>
              <TableCell align="right">{row.unit}</TableCell>
              <TableCell align="right">{row.labels}</TableCell>
              <TableCell align="right">{row.origin}</TableCell>
              <TableCell align="right">{row.cooking}</TableCell>
              <TableCell align="right">{row.packaging}</TableCell>
              <TableCell align="right">{row.storage}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}