import React from 'react';
import './ProgressBarWithMagnifier.css'; // Assurez-vous d'avoir un fichier CSS correspondant

const ProgressBarWithMagnifier = ({ value }) => {
  // Calculer la position de la loupe
  const magnifierPosition = (value / 10000) * 100;

  return (
    <div className="progress-bar-container">
      <img src="../../../ecbar.png" alt="barEC" className='progress-bar' width="100%"/>
      <div className="magnifier" style={{ left: `${magnifierPosition}%` }}>
        <img src="/loupe_vide.png" alt="loupe_vide" width="100%"/>
      </div>
    </div>
  );
};

export default ProgressBarWithMagnifier;
