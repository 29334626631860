// export variable fake_stats

export const fake_stats = {
    "id": "prod-2024-05",
    "Nutriscore_list": [
        "C",
        "C",
        "C",
        "C",
        "C",
        "C",
        "C",
        "B",
        "A",
        "B",
        "A",
        "A",
        "B",
        "A",
        "A",
        "B",
        "B",
        "D",
        "B",
        "D",
        "B",
        "A",
        "B",
        "A",
        "C",
        "C",
        "A",
        "C",
        "C"
    ],
    "Ecoscore_list": [
        "B",
        "B",
        "B",
        "B",
        "B",
        "B",
        "B",
        "B",
        "E",
        "C",
        "C",
        "E",
        "C",
        "E",
        "E",
        "C",
        "B",
        "A",
        "C",
        "A",
        "C",
        "E",
        "B",
        "E",
        "C",
        "B",
        "E",
        "B",
        "C"
    ],
    "Footprint_list": [
        415.7369569708045,
        415.7369569708045,
        415.7369569708045,
        415.7369569708045,
        415.7369569708045,
        415.7369569708045,
        415.7369569708045,
        652.9635810215001,
        3566.0787902260613,
        726.4528379607964,
        1315.226846827069,
        3566.0787902260613,
        726.4528379607964,
        3566.0787902260613,
        3566.0787902260613,
        726.4528379607964,
        652.9635810215001,
        674.9966414295138,
        726.4528379607964,
        674.9966414295138,
        726.4528379607964,
        3566.0787902260613,
        652.9635810215001,
        3566.0787902260613,
        1794.5758202037855,
        415.7369569708045,
        3566.0787902260613,
        415.7369569708045,
        1794.5758202037855
    ],
    "Recipes": [
        {
            "id": "e0395dab-203e-5449-a40f-6fed6c211b51",
            "nb_produced": 10,
            "Labels": {
                "aop": 1.914
            },
            "Mass_total": 50.400000000000006
        },
        {
            "id": "e0395dab-203e-5449-a40f-6fed6c211b51",
            "nb_produced": 10,
            "Labels": {
                "aop": 1.914
            },
            "Mass_total": 50.400000000000006
        },
        {
            "id": "e0395dab-203e-5449-a40f-6fed6c211b51",
            "nb_produced": 10,
            "Labels": {
                "aop": 1.914
            },
            "Mass_total": 50.400000000000006
        },
        {
            "id": "e0395dab-203e-5449-a40f-6fed6c211b51",
            "nb_produced": 10,
            "Labels": {
                "aop": 1.914
            },
            "Mass_total": 50.400000000000006
        },
        {
            "id": "e0395dab-203e-5449-a40f-6fed6c211b51",
            "nb_produced": 10,
            "Labels": {
                "aop": 1.914
            },
            "Mass_total": 50.400000000000006
        },
        {
            "id": "e0395dab-203e-5449-a40f-6fed6c211b51",
            "nb_produced": 10,
            "Labels": {
                "aop": 1.914
            },
            "Mass_total": 50.400000000000006
        },
        {
            "id": "e0395dab-203e-5449-a40f-6fed6c211b51",
            "nb_produced": 10,
            "Labels": {
                "aop": 1.914
            },
            "Mass_total": 50.400000000000006
        },
        {
            "id": "a6004554-6522-5dc6-a52c-bfaea11eea11",
            "nb_produced": 10,
            "Labels": {
                "bio": 8.4
            },
            "Mass_total": 59.65000000000001
        },
        {
            "id": "6105471a-3e23-5eda-b4ef-337912f15a4d",
            "nb_produced": 50,
            "Labels": {},
            "Mass_total": 37.288000000000004
        },
        {
            "id": "df082519-bb0d-5fdc-86e9-8258eb9f4b08",
            "nb_produced": 50,
            "Labels": {
                "bio": 6.647
            },
            "Mass_total": 35.032999999999994
        },
        {
            "id": "674d19cc-68e1-54b9-8a00-533cd52ad9da",
            "nb_produced": 10,
            "Labels": {},
            "Mass_total": 46.738
        },
        {
            "id": "6105471a-3e23-5eda-b4ef-337912f15a4d",
            "nb_produced": 5,
            "Labels": {},
            "Mass_total": 37.288000000000004
        },
        {
            "id": "df082519-bb0d-5fdc-86e9-8258eb9f4b08",
            "nb_produced": 10,
            "Labels": {
                "bio": 6.647
            },
            "Mass_total": 35.032999999999994
        },
        {
            "id": "6105471a-3e23-5eda-b4ef-337912f15a4d",
            "nb_produced": 20,
            "Labels": {},
            "Mass_total": 37.288000000000004
        },
        {
            "id": "6105471a-3e23-5eda-b4ef-337912f15a4d",
            "nb_produced": 50,
            "Labels": {},
            "Mass_total": 37.288000000000004
        },
        {
            "id": "df082519-bb0d-5fdc-86e9-8258eb9f4b08",
            "nb_produced": 20,
            "Labels": {
                "bio": 6.647
            },
            "Mass_total": 35.032999999999994
        },
        {
            "id": "a6004554-6522-5dc6-a52c-bfaea11eea11",
            "nb_produced": 10,
            "Labels": {
                "bio": 8.4
            },
            "Mass_total": 59.65000000000001
        },
        {
            "id": "e193da8a-c1fb-55bc-81e7-ff364c5c6606",
            "nb_produced": 10,
            "Labels": {
                "aop": 0.216
            },
            "Mass_total": 130.83100000000002
        },
        {
            "id": "df082519-bb0d-5fdc-86e9-8258eb9f4b08",
            "nb_produced": 20,
            "Labels": {
                "bio": 6.647
            },
            "Mass_total": 35.032999999999994
        },
        {
            "id": "e193da8a-c1fb-55bc-81e7-ff364c5c6606",
            "nb_produced": 10,
            "Labels": {
                "aop": 0.216
            },
            "Mass_total": 130.83100000000002
        },
        {
            "id": "df082519-bb0d-5fdc-86e9-8258eb9f4b08",
            "nb_produced": 10,
            "Labels": {
                "bio": 6.647
            },
            "Mass_total": 35.032999999999994
        },
        {
            "id": "6105471a-3e23-5eda-b4ef-337912f15a4d",
            "nb_produced": 20,
            "Labels": {},
            "Mass_total": 37.288000000000004
        },
        {
            "id": "a6004554-6522-5dc6-a52c-bfaea11eea11",
            "nb_produced": 1,
            "Labels": {
                "bio": 8.4
            },
            "Mass_total": 59.65000000000001
        },
        {
            "id": "6105471a-3e23-5eda-b4ef-337912f15a4d",
            "nb_produced": 2,
            "Labels": {},
            "Mass_total": 37.288000000000004
        },
        {
            "id": "2c58f1b8-a61e-5201-8606-bb8c7a503dc5",
            "nb_produced": 10,
            "Labels": {},
            "Mass_total": 55.888999999999996
        },
        {
            "id": "e0395dab-203e-5449-a40f-6fed6c211b51",
            "nb_produced": 1,
            "Labels": {
                "aop": 1.914
            },
            "Mass_total": 50.400000000000006
        },
        {
            "id": "6105471a-3e23-5eda-b4ef-337912f15a4d",
            "nb_produced": 24,
            "Labels": {},
            "Mass_total": 37.288000000000004
        },
        {
            "id": "e0395dab-203e-5449-a40f-6fed6c211b51",
            "nb_produced": 20,
            "Labels": {
                "aop": 1.914
            },
            "Mass_total": 50.400000000000006
        },
        {
            "id": "2c58f1b8-a61e-5201-8606-bb8c7a503dc5",
            "nb_produced": 30,
            "Labels": {},
            "Mass_total": 55.888999999999996
        }
    ],
    "Nb_total_portion_produced": 6925,
    "Label_quantities": {
        "aop": 0.025775306859205777,
        "bio": 0.13105703971119134
    },
    "_rid": "YS5uAK+I0doCAAAAAAAAAA==",
    "_self": "dbs/YS5uAA==/colls/YS5uAK+I0do=/docs/YS5uAK+I0doCAAAAAAAAAA==/",
    "_etag": "\"070034e1-0000-0e00-0000-662fea180000\"",
    "_attachments": "attachments/",
    "Nutriscore_repartion": {
        "A": 8,
        "B": 8,
        "C": 11,
        "D": 2,
        "E": 0
    },
    "Ecoscore_repartion": {
        "A": 2,
        "B": 12,
        "C": 8,
        "D": 0,
        "E": 7
    },
    "Average_footprint": 1398.2658912855795,
    "Nb_recette": 29,
    "Last_6months_footprint": [
        492
    ],
    "_ts": 1714416152
}