import React from 'react';
import './altrecipes.css'; // Votre CSS pour le tableau de bord
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useData } from '../../DataProvider';
import TextField from '@mui/material/TextField';
import RecipeCard from './RecipeCard';
import { useState } from 'react';
import { Button } from '@mui/material';

const alt1 = {
  "name": "Ajiaco Vegetarien aux Haricots",
  "ecoscore": "B",
  "nutriscore": "A",
  "footprint": "480",
  "ecoscore100": "85",
  "ingredients": [
    "Haricots noirs ou haricots rouges",
    "Pommes de terre",
    "Mais en epis",
    "Oignons",
    "Gousses d'ail",
    "Coriandre",
    "Creme fraiche ou creme vegetalienne",
    "Capres",
    "Avocats",
    "Cumin",
    "Sel",
    "Poivre"
  ],
  "etapes": [
    "Faire revenir les oignons et l'ail eminces dans une grande casserole jusqu'à ce qu'ils soient dores.",
    "Ajouter les pommes de terre coupees en morceaux, les epis de mais et les haricots.",
    "Ajouter de l'eau ou du bouillon de legumes, du cumin, du sel et du poivre, puis laisser mijoter jusqu'à ce que les pommes de terre soient tendres.",
    "Servir chaud, garni de creme fraiche, de capres, de tranches d'avocat et de coriandre fraiche."
  ],
  "quantites": {
    "portions": 30,
    "details": {
      "Haricots noirs ou haricots rouges": "3 kg",
      "Pommes de terre": "4.5 kg",
      "Mais en epis": "15 epis",
      "Oignons": "15",
      "Gousses d'ail": "24",
      "Coriandre": "7.5 bouquets",
      "Creme fraiche ou creme vegetalienne": "1.5 litres",
      "Capres": "375g",
      "Avocats": "15",
      "Cumin": "7.5 cuilleres à cafe",
      "Sel": "à gout",
      "Poivre": "à gout"
    }
  }
}

const alt2 = {
  "name": "Sancocho Vegetarien",
  "ecoscore": "A",
  "nutriscore": "A",
  "footprint": "420",
  "ecoscore100": "90",
  "ingredients": [
    "Yuca",
    "Plantains verts",
    "Pommes de terre",
    "Mais en epis",
    "Carottes",
    "Oignons",
    "Gousses d'ail",
    "Coriandre",
    "Cumin",
    "Sel",
    "Poivre"
  ],
  "etapes": [
    "Faire revenir les oignons et l'ail eminces dans une grande casserole.",
    "Ajouter les legumes coupes en morceaux : yuca, plantains, pommes de terre, carottes et epis de mais.",
    "Couvrir d'eau ou de bouillon de legumes, assaisonner avec du cumin, du sel et du poivre, puis laisser mijoter jusqu'à ce que tous les legumes soient tendres.",
    "Servir chaud, garni de coriandre fraiche."
  ],
  "quantites": {
    "portions": 30,
    "details": {
      "Yuca": "2.25 kg",
      "Plantains verts": "15",
      "Pommes de terre": "4.5 kg",
      "Mais en epis": "15 epis",
      "Carottes": "22.5",
      "Oignons": "15",
      "Gousses d'ail": "24",
      "Coriandre": "7.5 bouquets",
      "Cumin": "7.5 cuilleres à cafe",
      "Sel": "à gout",
      "Poivre": "à gout"
    }
  }
}

const alt3 = {
  "name": "Caldo Verde Vegetarien",
  "ecoscore": "B",
  "nutriscore": "A",
  "footprint": "400",
  "ecoscore100": "88",
  "ingredients": [
    "Pommes de terre",
    "Epinards ou chou frise (kale)",
    "Oignons",
    "Gousses d'ail",
    "Bouillon de legumes",
    "Creme fraiche ou creme vegetalienne",
    "Coriandre",
    "Sel",
    "Poivre"
  ],
  "etapes": [
    "Faire revenir les oignons et l'ail eminces dans une grande casserole.",
    "Ajouter les pommes de terre coupees en morceaux et couvrir de bouillon de legumes.",
    "Laisser mijoter jusqu'à ce que les pommes de terre soient tendres.",
    "Ajouter les epinards ou le chou frise et laisser cuire quelques minutes de plus.",
    "Mixer la soupe jusqu'à obtenir une texture lisse, puis incorporer la creme.",
    "Servir chaud, garni de coriandre fraiche."
  ],
  "quantites": {
    "portions": 30,
    "details": {
      "Pommes de terre": "4.5 kg",
      "Epinards ou chou frise (kale)": "1.5 kg",
      "Oignons": "15",
      "Gousses d'ail": "24",
      "Bouillon de legumes": "7.5 litres",
      "Creme fraiche ou creme vegetalienne": "1.5 litres",
      "Coriandre": "7.5 bouquets",
      "Sel": "à gout",
      "Poivre": "à gout"
    }
  }
}

const alt4 = {
  "name": "Lasagnes vegetariennes",
  "ecoscore": "B",
  "nutriscore": "A",
  "footprint": "800",
  "ecoscore100": "90",
  "ingredients": [
    "Feuilles de lasagne",
    "Courgettes",
    "Aubergines",
    "Poivron rouge",
    "Poivron jaune",
    "Champignons",
    "Oignons",
    "Gousses d'ail",
    "Tomates concassees",
    "Sauce tomate",
    "Bechamel",
    "Fromage rape",
    "Huile d'olive",
    "Sel",
    "Poivre",
    "Herbes de Provence"
  ],
  "etapes": [
    "Prechauffer le four à 180°C.",
    "Couper les courgettes, les aubergines, les poivrons et les champignons en des.",
    "Faire revenir les oignons et l'ail eminces dans l'huile d'olive.",
    "Ajouter les legumes et cuire jusqu'à ce qu'ils soient tendres.",
    "Ajouter les tomates concassees et la sauce tomate, puis assaisonner avec le sel, le poivre et les herbes de Provence.",
    "Dans un plat à lasagnes, alterner des couches de feuilles de lasagne, de legumes cuits et de bechamel.",
    "Terminer par une couche de bechamel et parsemer de fromage rape.",
    "Cuire au four pendant environ 30-40 minutes, jusqu'à ce que le dessus soit dore et bouillonnant."
  ],
  "quantites": {
    "portions": 30,
    "details": {
      "Feuilles de lasagne": "1.875 kg",
      "Courgettes": "15",
      "Aubergines": "15",
      "Poivron rouge": "7.5",
      "Poivron jaune": "7.5",
      "Champignons": "1.5 kg",
      "Oignons": "15",
      "Gousses d'ail": "24",
      "Tomates concassees": "3 kg",
      "Sauce tomate": "3.75 litres",
      "Bechamel": "3.75 litres",
      "Fromage rape": "1.5 kg",
      "Huile d'olive": "15 cuilleres à soupe",
      "Sel": "à gout",
      "Poivre": "à gout",
      "Herbes de Provence": "7.5 cuilleres à soupe"
    }
  }
}

const alt5 = {
  "name": "Lasagnes au poulet",
  "ecoscore": "C",
  "nutriscore": "B",
  "footprint": "1000",
  "ecoscore100": "70",
  "ingredients": [
    "Feuilles de lasagne",
    "Poulet",
    "Courgettes",
    "Aubergines",
    "Poivron rouge",
    "Poivron jaune",
    "Champignons",
    "Oignons",
    "Gousses d'ail",
    "Tomates concassees",
    "Sauce tomate",
    "Bechamel",
    "Fromage rape",
    "Huile d'olive",
    "Sel",
    "Poivre",
    "Herbes de Provence"
  ],
  "etapes": [
    "Prechauffer le four à 180°C.",
    "Couper le poulet en petits morceaux.",
    "Couper les courgettes, les aubergines, les poivrons et les champignons en des.",
    "Faire revenir les oignons et l'ail eminces dans l'huile d'olive.",
    "Ajouter le poulet et cuire jusqu'à ce qu'il soit dore.",
    "Ajouter les legumes et cuire jusqu'à ce qu'ils soient tendres.",
    "Ajouter les tomates concassees et la sauce tomate, puis assaisonner avec le sel, le poivre et les herbes de Provence.",
    "Dans un plat à lasagnes, alterner des couches de feuilles de lasagne, de melange de poulet et legumes, et de bechamel.",
    "Terminer par une couche de bechamel et parsemer de fromage rape.",
    "Cuire au four pendant environ 30-40 minutes, jusqu'à ce que le dessus soit dore et bouillonnant."
  ],
  "quantites": {
    "portions": 30,
    "details": {
      "Feuilles de lasagne": "1.875 kg",
      "Poulet": "3 kg",
      "Courgettes": "15",
      "Aubergines": "15",
      "Poivron rouge": "7.5",
      "Poivron jaune": "7.5",
      "Champignons": "1.5 kg",
      "Oignons": "15",
      "Gousses d'ail": "24",
      "Tomates concassees": "3 kg",
      "Sauce tomate": "3.75 litres",
      "Bechamel": "3.75 litres",
      "Fromage rape": "1.5 kg",
      "Huile d'olive": "15 cuilleres à soupe",
      "Sel": "à gout",
      "Poivre": "à gout",
      "Herbes de Provence": "7.5 cuilleres à soupe"
    }
  }
}

const alt6 = {
  "name": "Cannellonis",
  "ecoscore": "C",
  "nutriscore": "B",
  "footprint": "950",
  "ecoscore100": "72",
  "ingredients": [
    "Pates à cannellonis",
    "Ricotta",
    "Epinards",
    "Parmesan rape",
    "Oignons",
    "Gousses d'ail",
    "Tomates concassees",
    "Sauce tomate",
    "Mozzarella",
    "Huile d'olive",
    "Sel",
    "Poivre",
    "Noix de muscade"
  ],
  "etapes": [
    "Prechauffer le four à 180°C.",
    "Faire revenir les oignons et l'ail eminces dans l'huile d'olive.",
    "Ajouter les epinards et cuire jusqu'à ce qu'ils soient fondants.",
    "Melanger les epinards cuits avec la ricotta, le parmesan rape, le sel, le poivre et la noix de muscade.",
    "Farcir les pates à cannellonis avec ce melange.",
    "Dans un plat à gratin, etaler une couche de sauce tomate, puis disposer les cannellonis farcis par-dessus.",
    "Recouvrir de sauce tomate et parsemer de mozzarella rapee.",
    "Cuire au four pendant environ 30 minutes, jusqu'à ce que le dessus soit dore et bouillonnant."
  ],
  "quantites": {
    "portions": 30,
    "details": {
      "Pates à cannellonis": "1.875 kg",
      "Ricotta": "3 kg",
      "Epinards": "3.75 kg",
      "Parmesan rape": "750g",
      "Oignons": "15",
      "Gousses d'ail": "24",
      "Tomates concassees": "3 kg",
      "Sauce tomate": "3.75 litres",
      "Mozzarella": "1.5 kg",
      "Huile d'olive": "15 cuilleres à soupe",
      "Sel": "à gout",
      "Poivre": "à gout",
      "Noix de muscade": "7.5 cuilleres à cafe"
    }
  }
}



const AltRecipes = () => {
  let navigate = useNavigate();
  const naviguateToAddRecipes = () => {
    navigate('/addrecipes'); // Remplacez par le chemin de votre autre page
  };
  const [visibility, setVisibility] = useState('hidden');
  const [recherche, setRecherche] = useState('');
  const [fakeResults, setFakeResults] = useState([alt1, alt2, alt3]);
  const handleSubmit = (e) => {
    //visibility true for box
    if(recherche=="Lasagnes au boeuf"){
      setFakeResults([alt4, alt5, alt6]);
    }else if(recherche=="Ajiaco colombien"){
      setFakeResults([alt1, alt2, alt3]);
    }
    setVisibility('visible');

  };
  const { isLoading, error, altRecipes } = useData();
  if(isLoading){
    return(
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center' }} >
          <h1>Chargement des données</h1>
          <CircularProgress />
        </Box>
      </div>
    )
  }
  else if(error){
    /*return (
      <div className="dashboard">
        <Box component="div" sx={{ display: 'flex', flexDirection:'column', alignItems: 'center',justifyContent: 'center', verticalAlign: 'center' }} >
        <Alert severity="error" sx={{verticalAlign:'center'}}>
          <AlertTitle>Erreur</AlertTitle>
            Une erreur est survenue au chargement des données de votre restaurant. <br/>
            Rafraichissez la page, si cela ne fonctionne toujours pas, contactez le support (rubrique Aide & Contact).
        </Alert>
        </Box>
      </div>
    )*/
  }

  return (
    
    <div className="myrecipes">
      <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
        <img src="logo_bis.svg" alt="logo_bis" />
        <h1 style={{marginLeft:10}}>Alternatives durables du jour</h1>
      </Box>

      {/*
      <TextField
      label="Rechercher une recette"
      variant="outlined"
      onChange={(e) => setRecherche(e.target.value)}
      sx={{ 
        marginTop: 3,
        width: '40%', // Définir la largeur ici, 2 fois plus longue que par défaut
        borderRadius: 2,
        '& .MuiOutlinedInput-root': {
          borderRadius: '25px',
        },
      }}
    />
    <Button onClick={handleSubmit} sx={{ marginTop:4, marginLeft: 3 }} type="submit" variant="contained" color="primary"> Rechercher </Button>
        */
      }
      { altRecipes.length > 2 &&
      <Box component="div" sx={{ display: 'flex', alignItems: 'center',justifyContent: 'center', gap:5, marginTop:7}}>
        <RecipeCard recipe={altRecipes[0]}/>
        <RecipeCard recipe={altRecipes[1]}/>
        <RecipeCard recipe={altRecipes[2]}/>
      </Box>
      }

    </div>

  );
};

export default AltRecipes;