export const labels_list = [
    "nature&progres",
    "biocoherence",
    "demeter",
    "bio+equitable",
    "eubio",
    "ab",
    "bio" ,
    "hve" ,
    "utz" ,
    "rainforestalliance",
    "bbc",
    "asc",
    "msc",
    "label rouge"
]